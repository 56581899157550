import React from 'react';
import { GuriFormContainer, GuriFormElement, GuriForm } from '../../../common/common.styled';
import StepTitle from '../StepTitle';
import { Text } from '../../../components/form/guriSlider/GuriSlider.styled';
import GuriSelect from '../../../components/form/guriSelect/GuriSelect';
import GuriInput from '../../../components/form/guriInput/GuriInput';
import colors from '../../../constants/colors';
import GuriFile from '../../../components/form/guriFile/GuriFile';
import { GuriButton } from '../../../common/buttons.styled';
import { CardsImage } from '../../../assets/images/imageRepository';

const Finish = ({
  appInfo,
  activeStep,
  appErrors,
  handleTextInputChange,
  handleFileInputChange,
  handleSubmit,
  needsId,
  quoteInfo,
  handleTextInputChangeQuoteInfo
}) => {
  return (
    <GuriFormContainer>
      <StepTitle
        title="Contratación"
        subtitle="Protección de tu identidad"
      />
      <Text fontWeight="700" fontSize="10px" color={colors.bluedGray}>
        ¡Estás a un paso! Para continuar con la contratación de tu seguro es necesario que nos ayudes a  subir tu identificación oficial.
      </Text>
      <GuriForm margin="50px 0 0">
        <GuriFormElement>
          <GuriSelect
            id="idType"
            label="Tipo de identificación"
            placeholder="Tipo"
            value={appInfo[activeStep].idType}
            options={['Credencial para votar', 'Pasaporte', 'Licencia de conducir']}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].idType}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="idNumber"
            label="Número de identificacion"
            placeholder="Número"
            value={appInfo[activeStep].idNumber}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].idNumber}
            autocomplete="off"
          />
        </GuriFormElement>
        {needsId && (
          <>
            <GuriFormElement>
              <GuriFile
                id="idFront"
                label="Identificación oficial (adelante)"
                file={appInfo[activeStep].idFront}
                onChange={handleFileInputChange}
                errorMessage={appErrors[activeStep].idFront}
                fullWidth
              />
            </GuriFormElement>
            <GuriFormElement>
              <GuriFile
                id="idBack"
                label="Identificación oficial (atrás)"
                file={appInfo[activeStep].idBack}
                onChange={handleFileInputChange}
                errorMessage={appErrors[activeStep].idBack}
                fullWidth
              />
            </GuriFormElement>
            <GuriFormElement fullWidth>
              <GuriFile
                id="addressProof"
                label="Comprobante de domicilio"
                file={appInfo[activeStep].addressProof}
                onChange={handleFileInputChange}
                errorMessage={appErrors[activeStep].addressProof}
              />
            </GuriFormElement>
          </>
        )}
        {/* <GuriFormElement>
          <GuriInput
            id="reference"
            label="Si tienes un código de referido ingrésalo aquí"
            placeholder="Código de referido"
            hint="* Esto es sólo en caso de que seas referido por alguno de nuestros promotores"
            value={quoteInfo.reference || ''}
            onChange={handleTextInputChangeQuoteInfo}
            autocomplete="off"
          />
        </GuriFormElement> */}
        <GuriFormElement fullWidth margin="10px 0" paddingBottom="0">
          <Text fontSize="14px" fontWeight="700" color={colors.bluedGray}>
            Datos de pago domiciliado
          </Text>
        </GuriFormElement>
        <GuriFormElement fullWidth>
          <img src={CardsImage} alt="cards" style={{ width: '120px', height: 'auto' }} />
        </GuriFormElement>
        <GuriFormElement>
          <GuriSelect
            id="cardType"
            label="Tipo de tarjeta"
            placeholder="Tarjeta"
            value={appInfo[activeStep].cardType}
            options={['Débito', 'Crédito']}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].cardType}
          />
        </GuriFormElement>
        {appInfo[activeStep].cardType === 'Débito' ? (
          <>
            <GuriFormElement>
              <GuriInput
                id="CLABE"
                label="Cuenta CLABE"
                placeholder="No. CLABE"
                value={appInfo[activeStep].CLABE}
                onChange={handleTextInputChange}
                errorMessage={appErrors[activeStep].CLABE}
              />
            </GuriFormElement>
            <GuriFormElement>
              <GuriInput
                id="tddCardNumber"
                label="Número de tarjeta"
                placeholder="Número"
                value={appInfo[activeStep].tddCardNumber}
                type="number"
                maxlength="16"
                onChange={handleTextInputChange}
                errorMessage={appErrors[activeStep].tddCardNumber}
              />
            </GuriFormElement>
            <GuriFormElement>
              <GuriInput
                id="expirationDate"
                label="Fecha de expiración"
                placeholder="MM / AA"
                value={appInfo[activeStep].expirationDate}
                onChange={handleTextInputChange}
                errorMessage={appErrors[activeStep].expirationDate}
                type="cardExpirationDate"
              />
            </GuriFormElement>
          </>
        ) : (
            <>
              <GuriFormElement>
                <GuriInput
                  id="bank"
                  label="Banco"
                  placeholder="Nombre"
                  value={appInfo[activeStep].bank}
                  onChange={handleTextInputChange}
                  errorMessage={appErrors[activeStep].bank}
                />
              </GuriFormElement>
              <GuriFormElement>
                <GuriInput
                  id="cardNumber"
                  label="Número de tarjeta"
                  placeholder="Número"
                  type="number"
                  value={appInfo[activeStep].cardNumber}
                  onChange={handleTextInputChange}
                  errorMessage={appErrors[activeStep].cardNumber}
                />
              </GuriFormElement>
              <GuriFormElement>
                <GuriInput
                  id="expirationDate"
                  label="Fecha de expiración"
                  placeholder="MM / AA"
                  value={appInfo[activeStep].expirationDate}
                  onChange={handleTextInputChange}
                  errorMessage={appErrors[activeStep].expirationDate}
                  type="cardExpirationDate"
                />
              </GuriFormElement>
            </>
          )}
        <GuriFormElement fullWidth margin="20px 0 0">
          <Text fontSize="10px" color={colors.bluedGray}>
            *No se generará ningún cargo a la tarjeta hasta que la póliza sea validada por la aseguradora.
          </Text>
        </GuriFormElement>
        <GuriFormElement fullWidth>
          <GuriButton
            onClick={handleSubmit}
            fullWidth
            color={colors.blue}
            textColor={colors.white}
            height="42px"
            sm={{
              height: '32px',
              width: '180px',
            }}
          >
            Contratar
          </GuriButton>
        </GuriFormElement>
      </GuriForm>
    </GuriFormContainer>
  )
}

export default (Finish);