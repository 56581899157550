import moment from 'moment';

export const yearsArray = (max, future = false) => {
  const years = []
  let dateStart;
  let dateEnd;
  if (future) {
    dateStart = moment()
    dateEnd = moment().add(max, 'y');
  } else {
    dateEnd = moment()
    dateStart = moment().subtract(max, 'y');
  }

  while (dateEnd.diff(dateStart, 'years') >= 0) {
    years.push(dateStart.format('YYYY'))
    dateStart.add(1, 'year')
  }
  return years
 };

 export const formatNumber = (num = 0) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  
  return formatter.format(num);
};

export const popupCenter = (url, title, w, h) => {
  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const interWidth = document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const interHeight = document.documentElement.clientHeight
    ? document.documentElement.clientHeigh
    : window.screen.height;
  const width = window.innerWidth ? window.innerWidth : interWidth;
  const height = window.innerHeight ? window.innerHeight : interHeight;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(url, title, `scrollbars=yes, width=${w / systemZoom}, height=${h / systemZoom}, top=${top}, left=${left}`);

  // Puts focus on the newWindow
  if (window.focus) newWindow.focus();
  return newWindow;
};

export const openTab = (url) => {
  const tab = window.open(url, '_blank');
  if (window.focus) tab.focus();
  return tab;
}

export const getAge = (dateOfBirth) => {
  var today = new Date();
  var birthDate = new Date(dateOfBirth);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}