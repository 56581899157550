import file from './values.json';

export const checkWhichInfo = (type = "retirement", gender, smoke) => {
  switch (type) {
    case "retirement":
      if (gender === 'Hombre') {
        if (smoke === 'Fumador') return file.retiro.hombreFumador;
        else return file.retiro.hombre;
      } else if (gender === 'Mujer') {
        if (smoke === 'Fumador') return file.retiro.mujerFumador;
        else return file.retiro.mujer;
      }
      break;
    case "five_years":
      if (gender === 'Hombre') {
        if (smoke === 'Fumador') return file.retiro.men_five_years_smoker;
        else return file.retiro.men_five_years_non_smoker;
      } else if (gender === 'Mujer') {
        if (smoke === 'Fumador') return file.retiro.woman_five_years_smoker;
        else return file.retiro.woman_five_years_non_smoker;
      }
      break;
    case "ten_years":
      if (gender === 'Hombre') {
        if (smoke === 'Fumador') return file.retiro.men_ten_years_smoker;
        else return file.retiro.men_ten_years_non_smoker;
      } else if (gender === 'Mujer') {
        if (smoke === 'Fumador') return file.retiro.woman_ten_years_smoker;
        else return file.retiro.woman_ten_years_non_smoker;
      }
      break;
    case "fifteen_years":
      if (gender === 'Hombre') {
        if (smoke === 'Fumador') return file.retiro.men_fifteen_years_smoker;
        else return file.retiro.men_fifteen_years_non_smoker;
      } else if (gender === 'Mujer') {
        if (smoke === 'Fumador') return file.retiro.woman_fifteen_years_smoker;
        else return file.retiro.woman_fifteen_years_non_smoker;
      }
      break;
    case "twenty_years":
      if (gender === 'Hombre') {
        if (smoke === 'Fumador') return file.retiro.men_twenty_years_smoker;
        else return file.retiro.men_twenty_years_non_smoker;
      } else if (gender === 'Mujer') {
        if (smoke === 'Fumador') return file.retiro.woman_twenty_years_smoker;
        else return file.retiro.woman_twenty_years_non_smoker;
      }
      break;
    case "retirements_limited":
      if (gender === 'Hombre') {
        if (smoke === 'Fumador') return file.retiro.retirements_limited;
        else return file.retiro.retirements_limited;
      } else if (gender === 'Mujer') {
        if (smoke === 'Fumador') return file.retiro.retirements_limited;
        else return file.retiro.retirements_limited;
      }
      break;
    default:
      if (gender === 'Hombre') {
        if (smoke === 'Fumador') return file.retiro.hombreFumador;
        else return file.retiro.hombre;
      } else if (gender === 'Mujer') {
        if (smoke === 'Fumador') return file.retiro.mujerFumador;
        else return file.retiro.mujer;
      }
      break;
  }

  return null;
}

export const valoresPorEdad = (edad, information) => {
  if (edad >= 18 && edad < 50) {
    return information.valores.find(info => info.edad === edad);
  } else {
    return null;
  }
}
// agregar default to retirement to type
export const cotizar = (edad, aportacionAnual, incapacidad, gender, iAm, type) => {
  const infoComplete = checkWhichInfo(type, gender, iAm);
  const infoEdad = valoresPorEdad(edad, infoComplete);
  const constAportacionMinima = type === "retirement" ? 12000 :
    type === "five_years" ? 18000 :
      type === "retirements_limited" ? 16500 :
        type === "ten_years" ? 16000 :
        type === "retirements_limited" ? 17000 :
        type === "retirements_unlimited" ? 12000 :
          12000;

  const iterations = ((aportacionAnual - constAportacionMinima) / 250)
  const costoSobreMensualidad = type === "retirement" ? 1.044 :
    type === "five_years" ? 1.08 :
      type === "ten_years" ? 1.08 :
        type === "fifteen_years" ? 1.08 :
          type === "twenty_years" ? 1.08 :
            type === "retirements_limited" ? 1.08 :
            type === "retirements_unlimited" ? 0.96 :
              1.044;
  const inflacion = 1.035;

  const getInfo = (information, infoEdad) => {
    let result = information.initial;
    for (let index = iterations; index > 0; index--) {
      result += information.const;
    }
    ;
    result *= infoEdad;
    return Math.round(result);
  };

  const ATA = (infoI) => {
    let cantidad = 0;
    if (incapacidad)
      cantidad = aportacionAnual + getInfo(infoI, infoEdad.I, iterations);
    else
      cantidad = aportacionAnual;

    let suma = cantidad;

    if (type === "retirement") {
      for (let index = (edad + 1); index <= 64; index++) {
        cantidad = Math.round(cantidad * inflacion);
        suma += Math.round(cantidad);
      }
      ;
    } else if (type === "five_years") {
      for (let index = (edad + 1); index <= (edad + 4); index++) {
        cantidad = Math.round(cantidad * inflacion);
        suma += Math.round(cantidad);
      }
    } else if (type === "ten_years") {
      for (let index = (edad + 1); index <= (edad + 9); index++) {
        cantidad = Math.round(cantidad * inflacion);
        suma += Math.round(cantidad);
      }
    } else if (type === "fifteen_years") {
      for (let index = (edad + 1); index <= (edad + 14); index++) {
        cantidad = Math.round(cantidad * inflacion);
        suma += Math.round(cantidad);
      }
    } else if (type === "twenty_years") {
      for (let index = (edad + 1); index <= (edad + 19); index++) {
        cantidad = Math.round(cantidad * inflacion);
        suma += Math.round(cantidad);
      }
    } else if (type === "retirements_limited") {
      for (let index = (edad + 1); index <= (edad + 9); index++) {
        cantidad = Math.round(cantidad * inflacion);
        suma += Math.round(cantidad);
      }
    }

    return suma;
  };

  const getRTP = () => {
    const RTPData = [];
    infoEdad.RTP.forEach((el, index) => {
      RTPData.push({
        RTP: getInfo(infoComplete.RTP[index], el.value),
        message: el.message,
        subtitle: el.subtitle
      })
    });
    return RTPData;
  }

  if (incapacidad)
    aportacionAnual += getInfo(infoComplete.I, infoEdad.I);
  const AMAnual = Math.ceil(aportacionAnual * costoSobreMensualidad);

  const datos = {
    typeInvestment: type,
    RTP: getRTP(),
    ATA: ATA(infoComplete.I),
    ATI: getInfo(infoComplete.ATI, infoEdad.ATI),
    AM: Math.ceil(AMAnual / 12),
    AMAnual,
    NewAA: Math.ceil(aportacionAnual),
    SA: getInfo(infoComplete.SA, infoEdad.SA)
  // SA: aportacionAnual === constAportacionMinima ? getInfo(infoComplete.SA, infoEdad.SA) + 2 : getInfo(infoComplete.SA, infoEdad.SA)
  };
  return datos;
}