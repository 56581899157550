import React, { useState, useEffect } from 'react';
import { GuriSliderContainer, TextNexa, Text } from './GuriSlider.styled';
import colors from '../../../constants/colors';
import { formatNumber } from '../../../utils';

const GuriSlider = (props) => {
  const {
    coverageAmount,
    setQuoteInfo,
    min,
    max,
    cotizacion,
  } = props;
  const [maxSlider, setMaxSlider] = useState(0);
  const [inputStyle, setInputStyle] = useState({});

  useEffect(() => {
    setInputStyle({
      background: 'linear-gradient(to right, #324DF1 0%, #324DF1 20%, #EEF0F1 20%, #EEF0F1 100%)'
    });
    setMaxSlider((max - min) / 250);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRange = (e) => {
    const text = document.getElementById('textTop');
    const input = e.target;
    const value = input.value;
    const position = ((value / maxSlider) * 100);
    text.style.left = `${position}%`;
    input.style.background = 'linear-gradient(to right, #324DF1 0%, #324DF1 ' + position + '%, #EEF0F1 ' + position + '%, #EEF0F1 100%)';
    const actualValue = 250 * value + min;
    setQuoteInfo(quoteInfo => ({
      ...quoteInfo,
      coverageAmount: actualValue,
    }))
  }

  return(
    <GuriSliderContainer>
      <TextNexa fontSize="18px" textAlign="right" color={colors.blue} id="textTop">
        {formatNumber(parseInt(cotizacion.AM))} <span className="currency">MXN</span>
      </TextNexa>
      <input
        type="range"
        min="0"
        max={maxSlider}
        value={(coverageAmount - min) / 250}
        className="slider"
        onChange={handleRange}
        style={inputStyle}
      />
      <Text fontSize="12px" fontStyle="italic"  textAlign="center" color={colors.typography} margin="0" sm={{ maxWidth: '80%', margin: '0 auto 20px' }}>
        Te recomendamos el 10% ó más de tu ingreso mensual
      </Text>
    </GuriSliderContainer>
  )
};

export default GuriSlider;