import React from 'react';
import { NavbarContainer, LogoImage } from './Navbar.styled';
import { Logo } from '../../assets/images/imageRepository';

const Navbar = () => {
  return (
    <NavbarContainer>
      <a href="http://getguri.com">
        <LogoImage src={Logo} />
      </a>
    </NavbarContainer>
  );
};

export default Navbar;
