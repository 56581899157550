import styled from 'styled-components';
import { Container } from '../../common/common.styled';

export const DashboardContainer = styled(Container)`
display: flex;
flex-direction: column;
align-items: center;
margin-top: 20px;
img.logo{
  align-self: flex-start;
  width: 100px;
}
img.ilus{
  width: 700px;
  height: auto;
  margin: 30px 0 0;
}
`;

export const SocialLinks = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin: 50px 0;
a{
  margin: 0 10px;
  img{
    width: 20px;
    height: 20px;
    object-fit: contain;
    object-position: center;
  }
}
`;