import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Description from './views/description/Description';
import Quote from './views/quote/Quote';
import Application from './views/application/Application';
import Register from './views/register/Register';
import Dashboard from './views/dashboard/Dashboard';
import Login from './views/login/Login';
import Thanks from './views/thanks/Thanks';
import ROUTES from './constants/routes';
import SecuredRoute from './components/securedRoute/SecuredRoute';

function App() {
  return (
    <Router>
      <Switch>
        <Route from={ROUTES.REFERNCES} component={Description} />
        <Route exact path={ROUTES.DESCRIPTION} component={Quote} />

        <Route path={ROUTES.QUOTE_ROUTES} component={Quote} />
        <Redirect from={ROUTES.QUOTE} to="/quote/init" />

        <Route path={ROUTES.APPLICATION_ROUTES} component={Application} />
        <Redirect from={ROUTES.APPLICATION} to="/application/personal" />
        <Route path={ROUTES.REGISTER} component={Register} />
        <Route path={ROUTES.LOGIN} component={Login} />
        <Route path={ROUTES.THANKS} component={Thanks} />
        <SecuredRoute path={ROUTES.DASHBOARD} component={Dashboard} />
      </Switch>
    </Router>
  );
}

export default App;
