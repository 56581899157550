import styled, { css } from 'styled-components';
import colors from '../../../constants/colors';
import mediaQueries from '../../../constants/mediaQueries';

export const GuriSwitchContainer = styled.div`
display: flex;
align-items: center;
${mediaQueries.sm}{
  align-items: flex-start;
}
`;

export const GuriSwitchIndicator = styled.div`
flex: 0 0 40px;
height: 20px;
border-radius: 20px;
border: 1px solid ${colors.blueLight};
background: ${colors.blueLight};
position: relative;
cursor: pointer;
&:before{
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: ${colors.white};
  left: 2px;
  top: 2px;
  ${props => props.active && css`
    left: auto;
    right: 2px;
    background: ${colors.yellow};
  `}
}
`;

export const GuriSwitchInformation = styled.div`
margin-left: 10px;
flex: 1;
flex-shrink: 0;
`;