import React from 'react';
import PropTypes from 'prop-types';
import { GuriInputContainer, ErrorText, HelpLink } from './GuriInput.styled';
import colors from '../../../constants/colors';
import { Text } from '../../../common/typography.styled';
import CurrencyInput from './CurrencyInput';
import ExpirationDateInput from './ExpirationDateInput';
import DebitCardInput from './DebitCardInput';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const GuriInput = (props) => {
  const {id, label, hint, onChange, value, type, placeholder, errorMessage, maxWidth, alignment, sm, helpLink, marginBottom, isInAuthenticate, autocomplete, pattern, maxlength, } = props;

  // const ExampleCustomInput = forwardRef(
  //   ({value, onClick}, ref) => (
  //     <input onClick={onClick} style={{
  //       width: '100%'
  //     }} ref={ref} type="date" value={value} />
  //   ),
  // );

  const renderInput = () => {
    if (type === 'currency') {
      return (
        <CurrencyInput id={id} type={type} value={value} onChange={onChange} placeholder={placeholder} autoComplete={autocomplete} />
        );
    } else if (type === 'cardExpirationDate') {
      return (
        <ExpirationDateInput id={id} type={type} value={value} onChange={onChange} placeholder={placeholder} autoComplete={autocomplete} />
        );
    } else if (type === 'debitCardNumber') {
      return (
        <DebitCardInput id={id} type={type} value={value} onChange={onChange} placeholder={placeholder} autoComplete={autocomplete} />
        );
    } else if (type === 'datepicker') {
      return (
        <DatePicker
        peekNextMonth
        id="dateOfBirth"
        // selected={props && props.quoteInfo ? props.quoteInfo.dateOfBirthShow : props.value}
        // selected={props.value}
        // select={props.value}
        selected={props.value}
        showMonthDropdown
        showYearDropdown
        // fixedHeight
        dateFormat="dd/MM/yyyy"
        placeholderText="MM/DD/AAAA"
        dropdownMode="select"
        autoComplete="off"
        popperPlacement="auto"
        onChange={date => {
          // let stringDate = `${(date.getMonth() < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}/${(date.getDate() < 10) ? '0' + (date.getDate()) : date.getDate()}/${date.getFullYear()}`
          if (date === null) return
          let stringDate = date.toISOString().split('T')[0];
          props.onChange({
            dateWithOutFormmat: date,
            stringFormatted: stringDate
          })
        }}
        // onChange={(date) => {
        //   // let stringDate = `${(date.getMonth() < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}/${(date.getDate() < 10) ? '0' + (date.getDate()) : date.getDate()}/${date.getFullYear()}`
        //   if (date === null) return
        //   let stringDate = date.toISOString().split('T')[0];
        //   props.setQuoteInfo({
        //     ...props.quoteInfo,
        //     dateOfBirth: stringDate,
        //     dateOfBirthShow: date
        //   })
        // }}
        // customInput={<ExampleCustomInput />}
        />
        );
    }
    return (
      <input id={id} type={type} value={value} onChange={onChange} placeholder={placeholder} pattern={pattern} maxLength={maxlength} autoComplete={autocomplete} />
      );
  }
  const styleProp = type === "datepicker" ? {
    position: 'inherit'
  } : {};
  return (
    <GuriInputContainer
    maxWidth={maxWidth}
    alignment={alignment}
    sm={sm}
    displayerror={errorMessage ? 1 : 0}
    hasHelpLink={helpLink ? 1 : 0}
    marginBottom={marginBottom}
    isInAuthenticate={isInAuthenticate}
    style={styleProp}
    >
      {label && (
    <label>
          {label}
        </label>
    )}
      {hint && (
    <Text fontSize="10px" fontWeight="700" color={colors.bluedGray} margin={`${label ? '-20px' : '0'} 0 15px`}>
          {hint}
        </Text>
    )}
      {renderInput()}
      {helpLink && (
    <HelpLink
    onClick={helpLink.onClick}
    alignment={helpLink.alignment || 'left'}
    color={helpLink.color || colors.bluedGray}
    marginTop={helpLink.marginTop || '2px'}
    >
          {helpLink.text}
        </HelpLink>
    )}
      <ErrorText
    fontSize="10px"
    color={colors.red}
    hasHelpLink={helpLink ? 1 : 0}
    >
        {errorMessage}
      </ErrorText>
    </GuriInputContainer>
  )
};

GuriInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  maxWidth: PropTypes.string,
  alignment: PropTypes.string,
  sm: PropTypes.instanceOf(Object),
  helpLink: PropTypes.instanceOf(Object),
  marginBottom: PropTypes.string,
  isInAuthenticate: PropTypes.bool,
  autocomplete: PropTypes.string,
  pattern: PropTypes.string,
  maxlength: PropTypes.string,
};

GuriInput.defaultProps = {
  label: null,
  hint: null,
  type: 'text',
  placeholder: '',
  errorMessage: '',
  maxWidth: 'none',
  alignment: 'left',
  sm: null,
  helpLink: null,
  marginBottom: null,
  isInAuthenticate: false,
  autocomplete: 'on',
};

export default GuriInput;