import React from 'react';
import PropTypes from 'prop-types';
import { GuriInputContainer } from '../guriInput/GuriInput.styled';
import { GuriTogglerContainer, GuriTogglerOption } from './GuriToggler.styled';
import { Text } from '../../../common/typography.styled';
import colors from '../../../constants/colors';

const GuriToggler = (props) => {
  const {
    id,
    label,
    disclaimer,
    value,
    options,
    onChange,
    width,
    alignment,
    sm,
    tooltips,
  } = props;

  return (
    <GuriInputContainer>
      {label && (
        <label>
          {label}
        </label>
      )}
      { disclaimer && (
        <p style={{fontSize: '0.6em', marginTop: '-2.3em', marginBottom: '1em',}}>{disclaimer}</p>
      )}
      <GuriTogglerContainer width={width} alignment={alignment} sm={sm}>
        {options.map((o, index) => (
          <GuriTogglerOption
            key={o}
            active={o === value}
            onClick={() => onChange(id, o)}
            hasTooltip={tooltips && tooltips[index] ? 1 : 0}
          >
            {tooltips && tooltips[index] && (
              <span className="tooltip" dangerouslySetInnerHTML={{__html: tooltips[index]}} />
            )}
            <Text fontSize="14px" fontWeight="700" color={colors.darkBlue}>
              {o}
            </Text>
          </GuriTogglerOption>
        ))}
      </GuriTogglerContainer>
    </GuriInputContainer>
  );
};

GuriToggler.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.instanceOf(Array).isRequired,
  width: PropTypes.string,
  alignment: PropTypes.string,
  sm: PropTypes.instanceOf(Object),
  tooltips: PropTypes.instanceOf(Array),
};

GuriToggler.defaultProps = {
  label: null,
  width: '100%',
  alignment: 'left',
  sm: null,
  onChange: () => {},
  tooltips: null,
};

export default GuriToggler;