import styled, { css } from 'styled-components';
import colors from '../../../constants/colors';
import { CaretDown } from '../../../assets/images/imageRepository';
import mediaQueries from '../../../constants/mediaQueries';
import { Text } from '../../../common/typography.styled';

export const GuriInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: ${props => (props.maxWidth || 'none')};
  position: relative;
  overflow: visible;
  margin: ${props => (props.margin || '0')};
  margin-bottom: ${props => props.hasHelpLink ? '20px' : (props.marginBottom || '0')};
  label{
    color: ${colors.typography};
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 700;
    ${props => props.isInAuthenticate && css`
      color: black;
      margin-bottom: 5px;
    `}
    ${mediaQueries.sm}{
      font-size: 12px;
    }
  }
  input{
    padding: 10px 15px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
    color: ${colors.typography};
    background: ${colors.ultraLightBlue};
    border: 1px solid transparent;
    ${props => props.isInAuthenticate && css`
    color: ${colors.typography};
    background: transparent;
    padding: 10px 0;
    border: 0;
    border-bottom: 1px solid ${colors.lightGray};
    border-radius: 0;
    `}
    box-shadow: none;
    outline: 0;
    &::-webkit-input-placeholder { /* Edge */
      color: ${colors.bluedGray};
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: ${colors.bluedGray};
    }
    
    &::placeholder {
      color: ${colors.bluedGray};
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type="date"]
    {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      height: 20px;
      ${mediaQueries.sm}{
        min-height: 18px;
      }
      &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
    }
    &:-internal-autofill-selected{
      color: ${colors.darkBlue} !important;
      background: ${colors.ultraLightBlue} !important;
      ${props => props.isInAuthenticate && css`
        background: transparent !important;
      `}
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px ${props => props.isInAuthenticate ? 'white' : colors.ultraLightBlue} inset !important;
    }
    ${mediaQueries.sm}{
      font-size: 12px;
    }
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;       /* Remove default arrow */
    padding: 10px 15px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
    background-color: ${colors.ultraLightBlue};
    background-image: url(${CaretDown});   /* Add custom arrow */
    background-position: 95% center;
    background-size: 15px;
    background-repeat: no-repeat;
    border: 0;
    box-shadow: none;
    outline: 0;
    border: 1px solid transparent;
    ${mediaQueries.sm}{
      font-size: 12px;
    }
  }
  ${props => props.alignment === 'center' && css`
    margin: 0 auto;
    input{
      text-align: center;
    }
  `}
  ${mediaQueries.sm}{
    ${props => props.sm && css`
      max-width: ${props.sm.maxWidth || props.maxWidth || 'none'};
    `}
  }
  ${props => props.displayerror && css`
    input, select {
      border-color: ${colors.red};
    }
  `}
  ${props => props.isCheckbox && css`
  label.container{
    cursor: pointer;
    margin: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input{
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      height: 14px;
      width: 14px;
      background-color: ${colors.lightGray};
      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 4px;
        top: 2px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    input:checked ~ .checkmark {
      background-color: ${colors.lightBlue};
      &:after{
        display: block;
      }
    }
    p{
      margin-left: 25px;
    }
  }
  `}
`;

export const ErrorText = styled(Text)`
position: absolute;
top: ${props => props.hasHelpLink ? 'calc(100% + 17px)' : '100%'};
`;

export const HelpLink = styled.span`
position: absolute;
top: 100%;
margin-top: ${props => props.marginTop};
font-size: 10px;
color: ${props => props.color};
cursor: pointer;
${props => props.alignment === 'right' && css`
right: 0;
`}
`;

export const InputsContainer = styled.div`
display: flex;
justify-content: space-between;
input:first-child{
  flex: 0 0 calc(${props => props.firstWidth || '100%'} - 30px);
  max-width: calc(${props => props.firstWidth || '100%'} - 30px);
}
input:last-child{
  flex: 0 0 calc(${props => props.secondWidth || '100%'} - 30px);
  max-width: calc(${props => props.secondWidth || '100%'} - 30px);
}
`