import React, { useRef, useState, useEffect, } from 'react';
import moment from 'moment';
import { withRouter} from 'react-router-dom';
import Sidebar from '../../components/sidebar/Sidebar';
import {
  FormOuterContainer,
  FormContainer,
  ImageFullWidth,
  GuriFormContainer,
  GuriForm,
  GuriFormElement
} from '../../common/common.styled';
import { IlusHombre, CheckedYellow } from '../../assets/images/imageRepository';
import { TextNexa, Text } from '../../common/typography.styled';
import colors from '../../constants/colors';
import GuriInput from '../../components/form/guriInput/GuriInput';
import GuriSelect from '../../components/form/guriSelect/GuriSelect';
import GuriToggler from '../../components/form/guriToggler/GuriToggler';
import Benefits from '../../components/benefits/Benefits';
import { yearsArray, formatNumber, getAge } from '../../utils';
import { GuriButton } from '../../common/buttons.styled';
import { SliderContainer, FiscalContainer, BenefitsContainer, PaymentFrecuencyContainer, PaymentSelection, InvestmentContainer, InvestmentSelection } from './Quote.styled';
import GuriSlider from '../../components/form/guriSlider/GuriSlider';
import GuriSwitch from '../../components/form/guriSwitch/GuriSwitch';
import { valoresPorEdad, checkWhichInfo, cotizar } from '../../services/quote';
import { registerUser, validateUser } from '../../services/authentication';
import WhatsApp from '../../components/whatsapp/WhatsApp';
import GuriDoubleInput from '../../components/form/guriInput/GuriDoubleInput';

const Quote = (props) => {
  const {
    history
  } = props;

  let initialView = useRef(0);

  const [activeStep, setActiveStep] = useState(initialView.current);
  const [queryString, setQueryString] = useState({});

  const queryStringParse = (string) => {
    let parsed = {}
    if (string !== '') {
      string = string.substring(string.indexOf('?') + 1)
      let p1 = string.split('&')
      p1.forEach(function (value) {
        let params = value.split('=')
        parsed[params[0]] = params[1]
      });
    }
    console.log(parsed)
    return parsed
  }


  useEffect(() => {
    let queryStringParsed = queryStringParse(history.location.search)
    setQueryString(queryStringParsed)
    if (queryStringParsed.name && queryStringParsed.email) {
      console.log(decodeURIComponent(queryStringParsed.cotizacion))
      let guriQuoteInfo = {
        cigar: queryStringParsed.cigar,
        company: "6254e0f33a0a38688e4183ba",
        cotizacion: JSON.parse(decodeURIComponent(queryStringParsed.cotizacion)),
        coverageAmount: queryStringParsed.coverageAmount,
        dateOfBirth: queryStringParsed.dateOfBirth,
        dateOfBirthNative: new Date(queryStringParsed.dateOfBirth), //new Date(queryStringParsed.dateOfBirth).toISOString(),
        disabilityProtection: false,
        email: decodeURIComponent(queryStringParsed.email),
        fiscalBenefit: false,
        gender: queryStringParsed.gender === "man" ? "Hombre" : "Mujer",
        howReceive: "Pago único",
        iAm: queryStringParsed.iAm === "true" ? "Fumador" : "No fumador",
        lada: "00",
        lastname: queryStringParsed.lastname,
        name: queryStringParsed.name,
        otherType: queryStringParsed.otherType,
        paymentFrecuency: "Mensual",
        phone: queryStringParsed.phone,
        quantity: queryStringParsed.quantity,
        since: queryStringParsed.since,
        typeInvestment: queryStringParsed.typeInvestment,
        age: Number(queryStringParsed.age || 18),
        origin: queryStringParsed.origin,
      }
      setQuoteInfo(guriQuoteInfo)
      console.log(guriQuoteInfo.coverageAmount)
      if(guriQuoteInfo.cotizacion) {
        console.log("hola: ", guriQuoteInfo.cotizacion)
        setCotizacion(guriQuoteInfo.cotizacion)
      }
    }
  }, [history])

  useEffect(() => {
    if (queryString && queryString["company"]) localStorage.setItem("company", queryString["company"])
  }, [queryString])

  useEffect(() => {
    const guriQuoteItem = localStorage.getItem("guriQuoteInfo") || '{}'
    const guriQuoteItemParsed = JSON.parse(guriQuoteItem)
    if(Object.entries(guriQuoteItemParsed).length !== 0) {
      if (!guriQuoteItemParsed.age) {
       if(guriQuoteItemParsed.dateOfBirth) {
        guriQuoteItemParsed.age = getAge(guriQuoteItemParsed.dateOfBirth)
       }

       if(!guriQuoteItemParsed.aportacionMaxima || !guriQuoteItemParsed.aportacionMinima) {
        const info = checkWhichInfo(guriQuoteItemParsed.typeInvestment, guriQuoteItemParsed.gender, guriQuoteItemParsed.iAm);
        console.log("aqui: ", guriQuoteItemParsed.age, info)
        const { aportacionMaxima, aportacionMinima } = valoresPorEdad(guriQuoteItemParsed.age, info);
        guriQuoteItemParsed.aportacionMaxima = aportacionMaxima
        guriQuoteItemParsed.aportacionMinima = aportacionMinima
       }
      }
      setQuoteInfo(guriQuoteItemParsed)
      if (guriQuoteItemParsed.cotizacion) setCotizacion(guriQuoteItemParsed.cotizacion)
    }
    console.log("jeje", guriQuoteItemParsed)
  }, [queryString])

  useEffect(() => {
    switch (props.match.params.view) {
      case 'init':
        initialView.current = 0;
        break;
      case 'investment':
        initialView.current = 1;
        break;
      case 'coverage':
        initialView.current = 2;
        break;
      default:
        initialView.current = 0;
        break;
    }
    setActiveStep(initialView.current)
  }, [props.match.params.view], [initialView.current])
  const steps = [
    { name: 'Cuéntanos sobre ti', link: '/quote/init' },
    { name: 'Duración', link: '/quote/investment' },
    { name: 'Cobertura', link: '/quote/coverage' }
  ];
  const [isStepValid, setIsStepValid] = useState([false, true]);

  const emptyErrors = {
    name: '',
    lastname: '',
    dateOfBirth: '',
    quantity: '',
    since: '',
    email: '',
    lada: '',
    phone: '',
  };

  const [quoteErrors, setQuoteErrors] = useState(emptyErrors);

  const [quoteInfo, setQuoteInfo] = useState({
    name: '',
    lastname: '',
    dateOfBirth: '',
    gender: 'Hombre',
    iAm: 'No fumador',
    cigar: 'Si',
    otherType: 'Puro',
    typeInvestment: 'ten_years',
    quantity: '',
    since: '',
    email: '',
    lada: '',
    phone: '',
    aportacionMinima: 0,
    aportacionMaxima: 0,
    age: 0,
    coverageAmount: 0,
    howReceive: 'Pago único',
    fiscalBenefit: false,
    paymentFrecuency: 'Mensual',
    disabilityProtection: false,
  });
  const isFirstStepValid = (emailError = false) => {
    const {
      name,
      lastname,
      dateOfBirth,
      iAm,
      quantity,
      since,
      email,
      lada,
      phone,
    } = quoteInfo;

    setQuoteErrors(emptyErrors);

    let valid = true;
    //eslint-disable-next-line
    const rexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!name.trim()) {
      setQuoteErrors(errors => ({
        ...errors,
        name: 'Campo requerido',
      }))
      valid = false;
    }
    if (!lastname.trim()) {
      setQuoteErrors(errors => ({
        ...errors,
        lastname: 'Campo requerido',
      }))
      valid = false;
    }
    if (!dateOfBirth.trim()) {
      setQuoteErrors(errors => ({
        ...errors,
        dateOfBirth: 'Campo requerido',
      }))
      valid = false;
    }

    if (!phone.trim() || !lada.trim()) {
      setQuoteErrors(errors => ({
        ...errors,
        phone: 'Campo requerido',
      }))
      valid = false;
    }
    if (dateOfBirth) {
      const dateOfB = new Date(dateOfBirth);
      if (dateOfB && (new Date().getFullYear() - dateOfB.getFullYear()) < 18) {
        setQuoteErrors(errors => ({
          ...errors,
          dateOfBirth: 'Debes ser mayor de edad',
        }))
        valid = false;
      }

      if (dateOfB && (getAge(dateOfB) > 49)) {
        setQuoteErrors(errors => ({
          ...errors,
          dateOfBirth: 'Para poder contratar este plan debe contar con una edad menor a 49 años',
        }))
        valid = false;
      }
    }
    if (!email.trim()) {
      setQuoteErrors(errors => ({
        ...errors,
        email: 'Campo requerido',
      }))
      valid = false;
    }

    if (!rexEmail.test(email)) {
      setQuoteErrors(errors => ({
        ...errors,
        email: 'Ingresa un email válido',
      }))
      valid = false;
    }
    if (iAm === 'Fumador') {
      if (!quantity.trim()) {
        setQuoteErrors(errors => ({
          ...errors,
          quantity: 'Campo requerido',
        }))
        valid = false;
      }
      if (!since.trim()) {
        setQuoteErrors(errors => ({
          ...errors,
          since: 'Campo requerido',
        }))
        valid = false;
      }
    }
    if (emailError) {
      setQuoteErrors(errors => ({
        ...errors,
        email: 'Este correo ya está registrado en güri',
      }))
      valid = false
    }

    return valid;
  }
  const isSecondStepValid = () => {
    // const {
    //   lada, phone
    // } = quoteInfo;
    let valid = true;
    // if (!lada.trim()) {
    //   setQuoteErrors(errors => ({
    //     ...errors,
    //     lada: 'Campo requerido',
    //   }))
    //   valid = false;
    // }
    return valid;
  }

  const bottomSection = () => (!activeStep ? (
    <ImageFullWidth maxHeight="100%" src={IlusHombre} />
  ) : (
      <div style={{ padding: '0 20px' }}>
        <TextNexa fontSize="35px" color={colors.typography} sm={{ fontSize: '16px', textAlign: 'center', maxWidth: '80%', margin: '0 auto 10px' }}>
          Empieza a ahorrar hoy y disfruta lo que siempre has SOÑADO.
      </TextNexa>
        <Text fontSize="14px" color={colors.typography} margin="20px 0 0" sm={{ fontSize: '10px', textAlign: 'center', maxWidth: '80%', margin: '0 auto 10px' }}>
          Por suerte, güri te acompaña a mantener tu libertar financiera e incrementar tu estilo de vida sin importar la edad que tengas.
      </Text>
      </div>
    )
  );

  const handleTextInputChange = (event) => {
    const { id: field, value } = event.target;
    setQuoteInfo({
      ...quoteInfo,
      [field]: value,
    });
  };

  const handleTogglerChange = (field, value) => {
    setQuoteInfo(quote => (
      {
        ...quote,
        [field]: value,
      }
    ));
  }

  const [cotizacion, setCotizacion] = useState({
    AM: 0,
    AMAnual: 0,
    ATA: 0,
    ATI: 0,
    NewAA: 0,
    RTP: [
      {
        RTP: 0,
        subtitle: "Deja que tu dinero trabaje por ti, mantenlo y sigue generando rendimientos",
        message: "Recibirás a los '{years}' años:"
      }
    ],
    SA: 0
  })

  useEffect(() => {
    if (quoteInfo.coverageAmount) {
      // cotizar(edad, aportacionAnual, incapacidad, info, valoresPorEdad(edad, info))
      console.log("hello: ", quoteInfo)
      const {
        age,
        coverageAmount,
        disabilityProtection,
        gender,
        iAm,
        typeInvestment,
      } = quoteInfo;
      setCotizacion(cotizar(age, coverageAmount, disabilityProtection, gender, iAm, typeInvestment));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteInfo.coverageAmount, quoteInfo.disabilityProtection]);

  const handleNext = async () => {
    let emailTaken = false;
    try {
      const companyID = localStorage.getItem('company') || false;
      if(companyID) quoteInfo['company'] = companyID
      await validateUser(quoteInfo)
        .catch((err) => {
          if (err.response && err.response.status === 403) emailTaken = true;
        })
    } catch (err) {
      console.log("Error: ", err)
    }
    const valid = isFirstStepValid(emailTaken);
    setIsStepValid(steps => {
      const newSteps = [...steps];

      newSteps[0] = valid;
      return newSteps;
    })
    if (!valid) {
      return;
    }

    setActiveStep(1);
    history.push('/quote/investment')
  };

  const handleNextInvestment = async () => {
    console.log(quoteInfo)
    if(quoteInfo.origin && quoteInfo.origin === "assistant") {
      const age = moment().diff(quoteInfo.dateOfBirth, 'years');
      const info = checkWhichInfo(quoteInfo.typeInvestment, quoteInfo.gender, quoteInfo.iAm);
      const { aportacionMaxima, aportacionMinima } = valoresPorEdad(age, info);
      let quoteInfoSet = {
        ...quoteInfo,
        aportacionMaxima,
        aportacionMinima,
        age,
      }

      setQuoteInfo(quoteInfoSet);
      setActiveStep(2);
      history.push('/quote/coverage')
    } else {

      const age = moment().diff(quoteInfo.dateOfBirth, 'years');
      const info = checkWhichInfo(quoteInfo.typeInvestment, quoteInfo.gender, quoteInfo.iAm);
      const { aportacionMaxima, aportacionMinima } = valoresPorEdad(age, info);
      let quoteInfoSet = {
        ...quoteInfo,
        aportacionMaxima,
        aportacionMinima,
        age,
        coverageAmount: (aportacionMaxima + aportacionMinima) / 4,
      }

      setQuoteInfo(quoteInfoSet);
  
      setActiveStep(2);
      history.push('/quote/coverage')
    }
  }



  const handleSubmit = async () => {
    const valid = isSecondStepValid();
    setIsStepValid(steps => {
      const newSteps = [...steps];

      newSteps[1] = valid;

      return newSteps;
    })
    if (!valid) {
      return;
    }

    const referenceUser = localStorage.getItem('guriReference') || '';

    const {
      aportacionMaxima,
      aportacionMinima,
      age,
      ...info
    } = quoteInfo;
    const newInfo = {
      ...info,
      cotizacion: { ...cotizacion },
    };

    if (referenceUser) newInfo['reference'] = referenceUser;

    try {
      await registerUser({ ...newInfo })
      localStorage.setItem('guriQuoteInfo', JSON.stringify(newInfo));
      localStorage.removeItem('guriReference');
      history.push('/application');
    } catch (err) {
      console.log(err)
    }
  }

  const sliderSection = (
    <SliderContainer>
      <GuriSlider
        coverageAmount={quoteInfo.coverageAmount}
        setQuoteInfo={setQuoteInfo}
        min={quoteInfo.aportacionMinima}
        max={quoteInfo.aportacionMaxima}
        cotizacion={cotizacion && cotizacion}
      />
    </SliderContainer>
  );

  const paymentSelection = (
    <PaymentFrecuencyContainer>
      <PaymentSelection
        active={quoteInfo.paymentFrecuency === 'Mensual'}
        onClick={() => setQuoteInfo(q => ({
          ...q,
          paymentFrecuency: 'Mensual',
        }))}
      >
        <TextNexa fontSize="14px" textAlign="center" color={colors.typography}>
          MENSUAL
        </TextNexa>
        <TextNexa fontSize="24px" textAlign="center" color={colors.typography}>
          {formatNumber(cotizacion && cotizacion.AM)} al mes
        </TextNexa>
        <Text fontSize="11px" textAlign="center" color={colors.typography}>
          {formatNumber(cotizacion && cotizacion.AMAnual)} al año
        </Text>
      </PaymentSelection>
      <Text fontSize="14px" fontWeight="700" color={colors.blue} sm={{ margin: '0 0 20px' }}>
        ó
      </Text>
      <PaymentSelection
        active={quoteInfo.paymentFrecuency === 'Anual'}
        onClick={() => setQuoteInfo(q => ({
          ...q,
          paymentFrecuency: 'Anual',
        }))}
      >
        <TextNexa fontSize="14px" textAlign="center" color={colors.typography}>
          ANUAL
        </TextNexa>
        <TextNexa fontSize="24px" textAlign="center" color={colors.typography}>
          {formatNumber(cotizacion && cotizacion.NewAA)} al año
        </TextNexa>
        <Text fontSize="11px" textAlign="center" color={colors.typography}>
          Pago en una sola exhibición
        </Text>
        {quoteInfo.paymentFrecuency === 'Anual' && (
          <span className="bestOption">
            <img src={CheckedYellow} alt="checked" />
            <Text fontSize="10px" color={colors.typography}>
              Mejor<br />opción
            </Text>
          </span>
        )}
      </PaymentSelection>
    </PaymentFrecuencyContainer>
  );


  const investmentSelection = (
    <>
      <InvestmentContainer>
        <InvestmentSelection
            active={quoteInfo.typeInvestment === 'five_years'}
            onClick={() => setQuoteInfo(q => ({
              ...q,
              typeInvestment: 'five_years',
            }))}
          >
            <TextNexa fontSize="14px" textAlign="center" color={colors.typography}>
              Ahorra durante
          </TextNexa>
            <TextNexa fontSize="24px" padding="0.5em 0" textAlign="center" color={colors.typography}>
              5 años
          </TextNexa>
        </InvestmentSelection>
        <InvestmentSelection
          active={quoteInfo.typeInvestment === 'ten_years'}
          onClick={() => setQuoteInfo(q => ({
            ...q,
            typeInvestment: 'ten_years',
          }))}
        >
          <TextNexa fontSize="14px" textAlign="center" color={colors.typography}>
            Ahorra durante
        </TextNexa>
          <TextNexa fontSize="24px" padding="0.5em 0" textAlign="center" color={colors.typography}>
            10 años
        </TextNexa>
        </InvestmentSelection>
        <InvestmentSelection
          active={quoteInfo.typeInvestment === 'fifteen_years'}
          onClick={() => setQuoteInfo(q => ({
            ...q,
            typeInvestment: 'fifteen_years',
          }))}
        >
          <TextNexa fontSize="14px" textAlign="center" color={colors.typography}>
            Ahorra durante
        </TextNexa>
          <TextNexa fontSize="24px" padding="0.5em 0" textAlign="center" color={colors.typography}>
            15 años
        </TextNexa>
        </InvestmentSelection>
      </InvestmentContainer>
      <InvestmentContainer>
        <InvestmentSelection
          active={quoteInfo.typeInvestment === 'twenty_years'}
          onClick={() => setQuoteInfo(q => ({
            ...q,
            typeInvestment: 'twenty_years',
          }))}
        >
          <TextNexa fontSize="14px" textAlign="center" color={colors.typography}>
            Ahorra durante
        </TextNexa>
          <TextNexa fontSize="24px" padding="0.5em 0" textAlign="center" color={colors.typography}>
            20 años
        </TextNexa>
        </InvestmentSelection>
        <InvestmentSelection
          //flex='1'
          active={quoteInfo.typeInvestment === 'retirement'}
          onClick={() => setQuoteInfo(q => ({
            ...q,
            typeInvestment: 'retirement',
          }))}
        >
          <TextNexa fontSize="14px" textAlign="center" color={colors.typography}>
            Ahorra para tu retiro
        </TextNexa>
          <TextNexa fontSize="24px" padding="0.5em 0" textAlign="center" color={colors.typography}>
            Edad 65 años
        </TextNexa>
        </InvestmentSelection>
      </InvestmentContainer>
    </>
  );

  const form = () => {
    switch (activeStep) {
      case 0:
        return (
          <GuriFormContainer>
            <TextNexa fontSize="30px" color={colors.typography} maxWidth="420px">
              El plan ideal debe adaptarse a tu estilo de vida.
            </TextNexa>
            <GuriForm margin="55px 0 0" smMargin="30px 0 0">
              <GuriFormElement>
                <GuriInput
                  id="name"
                  label="Me llamo..."
                  placeholder="Nombre"
                  value={quoteInfo.name}
                  onChange={handleTextInputChange}
                  errorMessage={quoteErrors.name}
                />
              </GuriFormElement>
              <GuriFormElement>
                <GuriInput
                  id="lastname"
                  placeholder="Apellido"
                  value={quoteInfo.lastname}
                  onChange={handleTextInputChange}
                  errorMessage={quoteErrors.lastname}
                />
              </GuriFormElement>
              <GuriFormElement>
                <GuriInput
                  id="dateOfBirth"
                  label="Nací el..."
                  placeholder="DD/MM/AAAA"
                  value={quoteInfo.dateOfBirthNative}
                  type="datepicker"
                  errorMessage={quoteErrors.dateOfBirth}
                  onChange={(values) => {
                    setQuoteInfo({
                      ...quoteInfo,
                      dateOfBirth: values.stringFormatted,
                      dateOfBirthNative: values.dateWithOutFormmat
                    });
                  }}
                />
                
              </GuriFormElement>
              <GuriFormElement>
                <GuriInput
                  id="email"
                  label="Mi mail es..."
                  placeholder="Mail"
                  value={quoteInfo.email}
                  onChange={handleTextInputChange}
                  type="mail"
                  errorMessage={quoteErrors.email}
                />
              </GuriFormElement>
              <GuriFormElement>
                <GuriToggler
                  id="gender"
                  label="Sexo al nacer"
                  disclaimer="Sabemos que es 2022 pero tenemos que preguntarlo"
                  value={quoteInfo.gender}
                  options={['Hombre', 'Mujer']}
                  onChange={handleTogglerChange}
                />
              </GuriFormElement>

              <GuriFormElement>
                <GuriToggler
                  id="iAm"
                  label="Soy..."
                  value={quoteInfo.iAm}
                  options={['Fumador', 'No fumador']}
                  onChange={handleTogglerChange}
                />
              </GuriFormElement>
              {quoteInfo.iAm === 'Fumador' && (
                <>
                  <GuriFormElement>
                    <GuriToggler
                      id="cigar"
                      label="¿Cigarro?"
                      value={quoteInfo.cigar}
                      options={['Si', 'Otros']}
                      onChange={handleTogglerChange}
                    />
                  </GuriFormElement>
                  {quoteInfo.cigar === 'Otros' &&
                    <GuriFormElement>
                      <GuriToggler
                        id="otherType"
                        label="¿Qué tipo?"
                        value={quoteInfo.otherType}
                        options={['Puro', 'Pipa']}
                        onChange={handleTogglerChange}
                      />
                    </GuriFormElement>
                  }
                  <GuriFormElement>
                    <GuriInput
                      id="quantity"
                      label="Cantidad al mes"
                      placeholder="0"
                      value={quoteInfo.quantity}
                      onChange={handleTextInputChange}
                      type="number"
                      errorMessage={quoteErrors.quantity}
                    />
                  </GuriFormElement>
                  <GuriFormElement>
                    <GuriSelect
                      id="since"
                      label="¿Desde cuándo?"
                      placeholder="AAAA"
                      value={quoteInfo.since}
                      options={yearsArray(50)}
                      onChange={handleTextInputChange}
                      errorMessage={quoteErrors.since}
                    />
                  </GuriFormElement>
                </>
              )}
              <GuriFormElement>
                <GuriDoubleInput
                  label="Teléfono Móvil"
                  first={{
                    id: 'lada',
                    placeholder: 'Lada',
                    value: quoteInfo.lada,
                    type: 'tel',
                    maxlength: '3',
                    width: '30%'
                  }}
                  second={{
                    id: 'phone',
                    placeholder: 'Móvil',
                    value: quoteInfo.phone,
                    type: 'tel',
                    maxlength: '8',
                    width: '65%'
                  }}
                  onChange={handleTextInputChange}
                  errorMessage={quoteErrors.phone}
                />
              </GuriFormElement>
              <GuriFormElement fullWidth alignItems="flex-end" justifyContent="flex-end">
                <GuriButton
                  onClick={handleNext}
                  width="30%"
                  color={colors.blue}
                  textColor={colors.white}
                >
                  Siguiente
                </GuriButton>
              </GuriFormElement>
            </GuriForm>
          </GuriFormContainer>
        );
      case 1:
        return (
          <GuriFormContainer>
            <TextNexa fontSize="20px" margin="0 0 2em" color={colors.typography}>
              ¿Cuál de nuestros planes te interesa cotizar?
            </TextNexa>
            {investmentSelection}
            <GuriFormElement fullWidth alignItems="flex-end" justifyContent="flex-end">
              <GuriButton
                onClick={handleNextInvestment}
                width="30%"
                color={colors.blue}
                textColor={colors.white}
              >
                Siguiente
                </GuriButton>
            </GuriFormElement>
          </GuriFormContainer>
        )
      case 2:
        console.log("aqui ", quoteInfo, cotizacion)
        return (
          <GuriFormContainer>
            <TextNexa fontSize="20px" color={colors.typography}>
              {
                quoteInfo.typeInvestment === "retirement" ? '¿Cuánto vas a ahorrar para tu retiro al mes?' :
                '¿Cuánto vas a ahorrar al mes?'
              }
            </TextNexa>
            <GuriForm margin="20px 0 0">
              {sliderSection}
              <FiscalContainer>
                <TextNexa margin="20px 0" fontSize="20px" color={colors.typography}>
                  Cómo quieres realizar la aportación de tu plan
                </TextNexa>
                {paymentSelection}
                <Text fontSize="12px" textAlign="center" color={colors.typography} margin="20px 0" sm={{ maxWidth: '80%', margin: '0 auto 20px' }}>
                  *Tu ahorro se incrementará conforme inflación, ésto protege tu dinero. Así garantizas el valor de tu dinero a través del tiempo
                </Text>
                {/* <Text fontSize="14px" fontWeight="700" color={colors.bluedGray}>
                  ¿Cómo quieres recibir tu dinero cuando te retires?
                </Text>
                <Text fontSize="10px" fontWeight="600" fontStyle="italic" margin="0 0 10px" color={colors.bluedGray}>
                  escoge una y ésta puede cambiar cuando quieras
                </Text>
                <GuriFormElement fullWidth overflow="visible">
                  <GuriToggler
                    id="howReceive"
                    value={quoteInfo.howReceive}
                    options={['Pago único', 'Pago mensual']}
                    onChange={handleTogglerChange}
                    width="275px"
                    alignment="center"
                    sm={{ width: '100%' }}
                    tooltips={
                      [
                        '',
                        'Si decides esta modalidad: <ul><li>Solicitalo un año antes del vencimiento de tu póliza (edad 64).</li><li>Rentas vitalicias con periodo de garantía (monto garantizado durante el periodo de garantía).</li><li>Si falleces antes de concluir el periodo, las rentas faltantes se pagarán a tus beneficiarios. Si te encuentras con vida al concluir el periodo de garantía, se continuará con el pago de rentas hasta que fallezcas.</li></ul>'
                      ]
                    }
                  />
                </GuriFormElement> */}
              </FiscalContainer>
              <BenefitsContainer>
                <Benefits
                  cotizacion={cotizacion}
                ></Benefits>
              </BenefitsContainer>
              <Text fontSize="10px" color={colors.typography} margin="0 0 20px" sm={{ maxWidth: '80%', margin: '0 auto 20px' }}>
                Todos nuestros planes cuidan la tranquilidad de tus seres queridos en caso de fallecimiento.
              </Text>
              <GuriFormElement fullWidth>
                <GuriSwitch
                  value={quoteInfo.disabilityProtection}
                  name="Agregar protección en caso de incapacidad"
                  description="¿Has pensado quién se haría cargo de ti si hoy ya no pudieras trabajar? Garantiza tu independencia financiera.."
                  setQuoteInfo={setQuoteInfo}
                  id="disabilityProtection"
                />
              </GuriFormElement>
              {
                quoteInfo.typeInvestment === "retirement" && (
                  <GuriFormElement fullWidth>
                    <GuriSwitch
                      value={quoteInfo.fiscalBenefit}
                      name="Agregar beneficio fiscal -"
                      description={`A lo largo de tu contrato habrás obtenido un ahorro en impuestos de <span style="font-size: 15px">${cotizacion && formatNumber(cotizacion.ATI)}</span> MXN y así mismo todo lo que recibas no te generará ninguna retención.`}
                      setQuoteInfo={setQuoteInfo}
                      id="fiscalBenefit"
                    />
                  </GuriFormElement>
                )
              }
              {/* <GuriFormElement>
                <GuriDoubleInput
                  label="Teléfono Móvil"
                  first={{
                    id: 'lada',
                    placeholder: 'Lada',
                    value: quoteInfo.lada,
                    type: 'tel',
                    maxlength: '3',
                    width: '30%'
                  }}
                  second={{
                    id: 'phone',
                    placeholder: 'Móvil',
                    value: quoteInfo.phone,
                    type: 'tel',
                    maxlength: '8',
                    width: '65%'
                  }}
                  onChange={handleTextInputChange}
                  errorMessage={quoteErrors.phone}
                />
              </GuriFormElement> */}
              <GuriFormElement fullWidth justifyContent="flex-end">
                <GuriButton
                  onClick={handleSubmit}
                  color={colors.blue}
                  textColor={colors.white}
                  fontSize="10px"
                  uppercase
                  padding="15px 25px"
                >
                  Contratar en línea
                </GuriButton>
              </GuriFormElement>
            </GuriForm>
          </GuriFormContainer>
        );
      default:
        return null;
    }
  }

  return (
    <FormOuterContainer>
      <FormContainer>
        <Sidebar
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          steps={steps}
          isStepValid={isStepValid}
          bottomSection={bottomSection}
          isQuote
          history={history}
        />
        {form()}
      </FormContainer>
      <WhatsApp />
    </FormOuterContainer>
  )
};

export default withRouter(Quote);
