import styled from 'styled-components'
import { Container } from '../../common/common.styled'
import colors from '../../constants/colors';
import { Title } from '../../common/typography.styled';
import mediaQueries from '../../constants/mediaQueries';

export const DescriptionSection = styled.div`
width: 100%;
min-height: 100vh;
position: relative;
overflow: hidden;
`;

export const DescriptionContainer = styled(Container)`
  max-width: 700px;
  ${mediaQueries.sm}{
    max-width: 300px;
  }
`;

export const DescriptionTitle = styled(Title)`
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  span{
    color: ${colors.darkBlue};
    font-family: nexa;
  }
`;

export const DescriptionRow = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin: 45px 0;
${mediaQueries.sm}{
  justify-content: flex-start;
  flex-direction: column;
}
`;

export const DescriptionItem = styled.div`
flex: 0 0 31%;
display: flex;
flex-direction: column;
align-items: center;
jusitfy-content: center;
${mediaQueries.sm}{
  flex: 1;
  margin-bottom: 30px;
  max-width: 200px;
}
`;

export const DescriptionImage = styled.img`
width: 130px;
height: 130px;
object-fit: cover;
object-position: center;
border-radius: 100%;
margin-bottom: 20px;
`;

export const DescriptionFooter = styled.div`
margin: 0 auto;
max-width: 400px;
`;

export const DescriptionLinksContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-around;
${mediaQueries.sm}{
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 35px;
}
`;

export const DescriptionLink = styled.a`
cursor: pointer;
display: flex;
text-decoration: none;
margin-bottom: 15px;
p{
  display: flex;
  align-items: center;
}
`;

export const DescriptionLinkImage = styled.img`
width: 20px;
height: 20px;
object-fit: contain;
object-position: center;
`;

export const DescriptionCircle = styled.div`
position: absolute;
width: 250px;
height: 250px;
border-radius: 100%;
background: ${colors.lightGray};
bottom: -125px;
left: -125px;
${mediaQueries.sm}{
  width: 150px;
  height: 150px;
  bottom: -75px;
  left: -75px;
}
`;