import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../constants/colors';
import { Text } from '../../../common/typography.styled';
import { GuriInputContainer } from '../guriInput/GuriInput.styled';

const GuriCheckbox = (props) => {

  const {
    id,
    value,
    onChange,
    maxWidth,
    margin,
    label,
    labelColor,
    labelSize,
    sm,
  } = props;

  return (
    <GuriInputContainer
      sm={sm}
      maxWidth={maxWidth}
      margin={margin}
      labelColor={labelColor}
      labelSize={labelSize}
      isCheckbox
    >
      <label className="container">
        <input id={id} type="checkbox" checked={value} onChange={onChange} />
        <span className="checkmark"></span>
        <Text fontSize={labelSize || '10px'} color={labelColor || colors.bluedGray}>
          {label}
        </Text>
      </label>
    </GuriInputContainer>
  );
};

GuriCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  sm: PropTypes.instanceOf(Object),
  maxWidth: PropTypes.string,
  margin: PropTypes.string,
  labelColor: PropTypes.string,
  labelSize: PropTypes.string,
};

GuriCheckbox.defaultProps = {
  label: null,
  sm: null,
  maxWidth: null,
  margin: null,
  labelColor: null,
  labelSize: null,
};

export default GuriCheckbox;