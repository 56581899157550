import React, { useRef, useEffect, useState } from 'react';
import Geocode from "react-geocode";
import { withRouter } from 'react-router-dom';
import { FormOuterContainer, FormContainer, GuriFormElement } from '../../common/common.styled';
import Sidebar from '../../components/sidebar/Sidebar';
import sidebarBottomSection from './SideBarBottomSection';
import { Text } from '../../common/typography.styled';
import colors from '../../constants/colors';
import GuriInput from '../../components/form/guriInput/GuriInput';
import { countryCode } from '../../constants/countries';
// import { openTab } from '../../utils';
import { BeneficiariesSectionContainer, SectionTitle, AddBeneficiaryButton, ReferencesContainer, ReferenceContainer } from './Application.styled';
// import { createGNPinsurancePDF } from '../../services/docusign'
import Beneficiary from './Beneficiary';
import Terms from './Terms';
import { uploadApplication, getCompany } from '../../services/user';
import Personal from './views/Personal';
import Address from './views/Address';
import Salud from './views/Salud';
import Finish from './views/Finish';
import { RFC_REGEX } from '../../constants/regex';
import WhatsApp from '../../components/whatsapp/WhatsApp';

const Application = (props) => {
  const {
    history
  } = props;
  const emptyBeneficary = {
    name: '',
    lastname: '',
    secondLastname: '',
    relationship: '',
    percentage: '',
    dueDate: '',
    dueDateNative: '',
  };

  const emptyErrors = [
    {
      name: '',
      lastname: '',
      secondLastname: '',
      maritalStatus: '',
      dateOfBirth: '',
      country: '',
      nationality: '',
      birthCity: '',
      birthState: '',
      occupation: '',
      CURP: '',
      companyName: '',
      workPhone: '',
      monthlyIncome: '',
      workAddress: '',
      businessActivity: '',
      job: '',
      activities: '',
      occupationRisksSpecifications: '',
      governmentPositionSpecifications: '',
      rfc: '',
    },
    {
      street: '',
      exterior: '',
      delegacion: '',
      zip: '',
      colonia: '',
      state: '',
      city: '',
      country: '',
    },
    {
      weight: '',
      height: '',
      familyDiabetesSpecifications: '',
      surgeySpecifications: '',
      pilotFile: '',
      criminalRecordSpecifications: '',
      criminalRecordFile: '',
      insuranceRejectedSpecifications: '',
      currentlyInsuredSpecifications: '',
      criticalDiseaseSpecifications: '',
      unreferredCriticalDiseaseSpecifications: '',
      alcoholSpecsType: '',
      alcoholSpecsQuantity: '',
      alcoholSpecsFrecuency: '',
      alcoholSpecsSince: '',
      drugsSpecsType: '',
      drugsSpecsQuantity: '',
      drugsSpecsFrecuency: '',
      drugsSpecsSince: '',
      motoSpecifications: '',
      privateAircraftsSpecifications: '',
      professionalSportSpecifications: '',
      beneficiaries: [{ ...emptyBeneficary }],
      totalPercentage: '',
      references: [
        {
          name: '',
          phone: '',
        },
        {
          name: '',
          phone: '',
        },
        {
          name: '',
          phone: '',
        }
      ],
    },
    {
      idType: '',
      idNumber: '',
      idFront: '',
      idBack: '',
      addressProof: '',
      cardType: '',
      bank: '',
      CLABE: '',
      cardNumber: '',
      tddCardNumber: '',
      expirationDate: '',
    }
  ];

  const [setCompany] = useState(false);
  const [appErrors, setAppErrors] = useState(emptyErrors);

  const [appInfo, setAppInfo] = useState([
    {
      name: '',
      lastname: '',
      secondLastname: '',
      gender: 'Hombre',
      maritalStatus: '',
      dateOfBirth: '',
      country: '',
      nationality: '',
      birthCity: '',
      birthState: '',
      occupation: '',
      CURP: '',
      companyName: '',
      workPhone: '',
      monthlyIncome: '',
      workAddress: '',
      businessActivity: '',
      job: '',
      activities: '',
      occupationRisks: 'No',
      occupationRisksSpecifications: '',
      governmentPosition: 'No',
      governmentPositionSpecifications: '',
      rfc: '',
    },
    {
      street: '',
      exterior: '',
      interior: '',
      delegacion: '',
      zip: '',
      colonia: '',
      state: '',
      city: '',
      country: '',
      receiveInfo: true,
    },
    {
      weight: '',
      height: '',
      familyDiabetes: 'No',
      familyDiabetesSpecifications: '',
      surgery: 'No',
      surgerySpecifications: '',
      pilot: 'No',
      pilotFile: null,
      criminalRecord: 'No',
      criminalRecordSpecifications: '',
      criminalRecordFile: null,
      insuranceRejected: 'No',
      insuranceRejectedSpecifications: '',
      currentlyInsured: 'No',
      currentlyInsuredSpecifications: '',
      criticalDisease: 'No',
      criticalDiseaseSpecifications: '',
      unreferredCriticalDisease: 'No',
      unreferredCriticalDiseaseSpecifications: '',
      alcohol: 'No',
      alcoholSpecsType: '',
      alcoholSpecsQuantity: '',
      alcoholSpecsFrecuency: '',
      alcoholSpecsSince: '1970',
      drugs: 'No',
      drugsSpecsType: '',
      drugsSpecsQuantity: '',
      drugsSpecsFrecuency: '',
      drugsSpecsSince: '1970',
      drugsAlcoholPast: '',
      moto: 'No',
      motoSpecifications: '',
      privateAircrafts: 'No',
      privateAircraftsSpecifications: '',
      professionalSport: 'No',
      professionalSportSpecifications: '',
      beneficiaries: [{ ...emptyBeneficary }],
      // beneficiariesAddress: '',
      references: [
        {
          name: '',
          phone: '',
        },
        {
          name: '',
          phone: '',
        },
        {
          name: '',
          phone: '',
        }
      ]
    },
    {
      idType: '',
      emisor: '',
      idNumber: '',
      idFront: null,
      idBack: null,
      addressProof: null,
      cardType: '',
      bank: '',
      CLABE: '',
      cardNumber: '',
      expirationDate: '',
      tddCardNumber: '',
    }
  ]);

  let initialView = useRef(0);
  const [activeStep, setActiveStep] = useState(initialView.current);
  useEffect(() => {
    switch (props.match.params.view) {
      case 'personal':
        initialView.current = 0;
        break;
      case 'address':
        initialView.current = 1;
        break;
      case 'health':
        initialView.current = 2;
        break;
      case 'finish':
        initialView.current = 3;
        break;
      default:
        initialView.current = 0;
        break;
    }
    setActiveStep(initialView.current)
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [props.match.params.view], [initialView.current])

  const steps = [
    { name: 'Personal', link: '/application/personal' },
    { name: 'Contacto', link: '/application/address' },
    { name: 'Salud', link: '/application/health' },
    { name: 'Contratación', link: '/application/finish' }
  ];
  const [isStepValid, setIsStepValid] = useState([false, false, false, false]);
  const [quoteInfo, setQuoteInfo] = useState(null);
  const [needsId, setNeedsId] = useState(true);

  useEffect(() => {
    const info = localStorage.getItem('guriQuoteInfo');
    if (!info) {
      history.push('/quote')
      return;
    }

    const parsedInfo = JSON.parse(info);
    setQuoteInfo(parsedInfo);

    const {
      name,
      lastname,
      dateOfBirth,
      gender,
      paymentFrecuency,
      cotizacion,
    } = parsedInfo;

    setAppInfo(() => {
      const newInfo = [...appInfo]
      newInfo[0] = {
        ...newInfo[0],
        name,
        lastname,
        dateOfBirth,
        gender,
      };
      return newInfo;
    })
    Geocode.setApiKey('AIzaSyDaOGvXv5FUP4c7bNhojNJ9499Sd9s6R7I');

    if ((paymentFrecuency === 'Anual' && cotizacion.NewAA >= 40000)
      || (paymentFrecuency === 'Mensual' && cotizacion.AMAnual >= 40000)) {
      setNeedsId(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [zipCodeOptions, setZipCodeOptions] = useState([])

  useEffect(() => {
    if (appInfo[1].country) {
      if (appInfo[1].zip.length > 4) {
        Geocode.fromAddress(`${appInfo[1].zip}, ${appInfo[1].country}`, null, null, countryCode[appInfo[1].country]).then(response => {
          const addressComponents = response.results[0]['address_components'];
          const colonia = addressComponents[1]['short_name'];
          const postCodeLocalities = response.results[0]['postcode_localities'];
          setZipCodeOptions(postCodeLocalities ? postCodeLocalities : [colonia])
          const city = addressComponents[2]['short_name'];
          const state = addressComponents[3]['short_name'];
          setAppInfo(() => {
            const newInfo = [...appInfo]
            newInfo[1] = {
              ...newInfo[1],
              colonia,
              city,
              state,
            };
            return newInfo;
          })
        },
          error => {
            console.error(error);
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appInfo[1].country, appInfo[1].zip])


  const validateStep = (step) => {
    let valid = true;
    const newErrors = [...appErrors];

    switch (step) {
      case 0:
        for (const key in appInfo[step]) {
          if (key === 'occupationRisksSpecifications' && appInfo[step]['occupationRisks'] !== 'Si') continue;
          if (key === 'governmentPositionSpecifications' && appInfo[step]['governmentPosition'] !== 'Si') continue;

          if (!appInfo[step][key].trim()) {
            newErrors[step] = {
              ...newErrors[step],
              [key]: 'Campo requerido',
            };
            valid = false;
          } else {
            newErrors[step] = {
              ...newErrors[step],
              [key]: '',
            };
          }
          if (key === 'rfc' && !RFC_REGEX.test(appInfo[step].rfc)) {
            newErrors[step] = {
              ...newErrors[step],
              [key]: 'RFC inválido',
            };
            valid = false;
          }
        }
        break;
      case 1:
        for (const key in appInfo[step]) {
          if (key === 'interior') continue;
          if (key === 'receiveInfo') continue;

          if (!appInfo[step][key].trim()) {
            newErrors[step] = {
              ...newErrors[step],
              [key]: 'Campo requerido',
            };
            valid = false;
          } else {
            newErrors[step] = {
              ...newErrors[step],
              [key]: '',
            };
          }
        }
        break;
      case 2:
        const getMainKey = (key) => {
          let splittedKeyArray = key.split('Specifications');
          if (splittedKeyArray.length > 1) return splittedKeyArray[0];

          splittedKeyArray = key.split('File');
          if (splittedKeyArray.length > 1) return splittedKeyArray[0];

          splittedKeyArray = key.split('Specs');
          if (splittedKeyArray.length > 1) return splittedKeyArray[0];

          return null;
        };
        for (const key in appInfo[step]) {
          if (key === 'drugsAlcoholPast') continue;
          if (key === 'beneficiaries' || key === 'references') {
            for (let i = 0; i < appInfo[step][key].length; i++) {
              const b = appInfo[step][key][i];
              for (const bKey in b) {
                if(bKey === 'dueDateNative') continue;
                if (!b[bKey].trim()) {
                  newErrors[step][key][i] = {
                    ...newErrors[step][key][i],
                    [bKey]: 'Campo requerido',
                  };
                  valid = false;
                } else {
                  newErrors[step][key][i] = {
                    ...newErrors[step][key][i],
                    [bKey]: '',
                  };
                }
              }
            }
            continue;
          }

          const mainKey = getMainKey(key);
          if (mainKey && appInfo[step][mainKey] !== 'Si') {
            newErrors[step] = {
              ...newErrors[step],
              [key]: '',
            };
            continue;
          }
          if (!appInfo[step][key]) {
            newErrors[step] = {
              ...newErrors[step],
              [key]: 'Campo requerido',
            };
            valid = false;
          } else if (typeof (appInfo[step][key]) === 'string' && !appInfo[step][key].trim()) {
            newErrors[step] = {
              ...newErrors[step],
              [key]: 'Campo requerido',
            };
            valid = false;
          } else if (mainKey || key === 'weight' || key === 'height') {
            newErrors[step] = {
              ...newErrors[step],
              [key]: '',
            };
          }
        }
        const totalPercentage = Object.values(
          appInfo[step].beneficiaries
        ).reduce(
          (t, { percentage }) => t + parseInt(percentage.split('%')[0]),
          0);

        if (totalPercentage !== 100) {
          newErrors[step] = {
            ...newErrors[step],
            totalPercentage: 'error',
          };
          valid = false;
        } else {
          newErrors[step] = {
            ...newErrors[step],
            totalPercentage: '',
          };
        }

        break;
      case 3:
        for (const key in appInfo[step]) {
          if (key === 'idFront' && !needsId) continue;
          if (key === 'idBack' && !needsId) continue;
          if (key === 'addressProof' && !needsId) continue;
          if (key === 'emisor') continue;
          if ((key === 'CLABE' || key === 'tddCardNumber') && appInfo[step].cardType === 'Crédito') continue;
          if ((key === 'cardNumber' || key === 'bank') && appInfo[step].cardType === 'Débito') continue;
          if (!appInfo[step][key]) {
            newErrors[step] = {
              ...newErrors[step],
              [key]: 'Campo requerido',
            };
            valid = false;
          } else if (typeof (appInfo[step][key]) === 'string' && !appInfo[step][key].trim()) {
            newErrors[step] = {
              ...newErrors[step],
              [key]: 'Campo requerido',
            };
            valid = false;
          } else {
            newErrors[step] = {
              ...newErrors[step],
              [key]: '',
            };
          }

          if (key === 'tddCardNumber' && appInfo[step].tddCardNumber && appInfo[step].tddCardNumber.length !== 16 && appInfo[step].cardType === 'Débito') {
            newErrors[step] = {
              ...newErrors[step],
              tddCardNumber: 'Debes ingresar un numero de tarjeta válida de 16 dígitos.',
            };
            valid = false;
          }

          if (key === 'cardNumber' && appInfo[step].cardNumber && (appInfo[step].cardNumber.length < 15 || appInfo[step].cardNumber.length > 16) && appInfo[step].cardType === 'Crédito') {
            newErrors[step] = {
              ...newErrors[step],
              cardNumber: 'Debes ingresar un numero de tarjeta válida de 15 ó 16 dígitos.',
            };
            valid = false;
          }
        }
        break;
      default:
        break;
    }
    setAppErrors(newErrors);
    return valid;
  };

  const handleTextInputChangeReference = (event, index, fieldName) => {
    const { value } = event.target;
    const newInfo = [...appInfo];
    newInfo[activeStep].references[index] = {
      ...newInfo[activeStep].references[index],
      [fieldName]: value,
    };

    setAppInfo(newInfo);
  };

  const referencesSection = () => (
    <ReferencesContainer>
      <SectionTitle
        tooltipContent="*Se contactarán vía telefónica, para corroborar información de esta solicitud."
        tooltipLeft="270px"
        marginBottom="25px"
      >
        3 referencias personales (no familiares)
      </SectionTitle>
      {appInfo[activeStep].references && appInfo[activeStep].references.map((r, index) => (
        <ReferenceContainer key={`reference_${index}`}>
          <GuriFormElement>
            <GuriInput
              id={`name_${index}`}
              label="Nombre completo"
              placeholder={`Referencia ${index + 1}`}
              value={r.name}
              onChange={e => handleTextInputChangeReference(e, index, 'name')}
              errorMessage={appErrors[activeStep].references[index].name}
            />
          </GuriFormElement>
          <GuriFormElement>
            <GuriInput
              id={`phone_${index}`}
              label="Teléfono"
              placeholder={`Tel ${index + 1}`}
              value={r.phone}
              onChange={e => handleTextInputChangeReference(e, index, 'phone')}
              errorMessage={appErrors[activeStep].references[index].phone}
            />
          </GuriFormElement>
        </ReferenceContainer>
      ))}
    </ReferencesContainer>
  );

  const addBeneficiary = () => {
    setAppInfo(appInfo => {
      const newInfo = [...appInfo];

      newInfo[activeStep].beneficiaries.push({ ...emptyBeneficary });

      return newInfo;
    });
    setAppErrors(errors => {
      const newErrors = [...errors];

      newErrors[activeStep].beneficiaries.push({ ...emptyBeneficary });

      return newErrors;
    });
  }

  const beneficiariesSection = () => (
    <BeneficiariesSectionContainer>
      <SectionTitle
        tooltipContent="*Advertencia: En caso de que desee nombrar como beneficiarios a menores de edad, no se debe señalar a un mayor de edad como representante de los menores para efecto de que, en su representación, cobre la indemnización. Lo anterior porque las legislaciones civiles previenen la forma en que debe designarse tutores, albaceas, representantes de herederos u otros cargos similares y no consideran al Contrato de Seguro como un instrumento adecuado para tales designaciones. La designación que se hiciera de un mayor de edad como representante de menores beneficiarios, durante la minoría de edad de ellos, legalmente puede implicar que se nombre beneficiario al mayor de edad, quien en todo caso sólo tendrá una obligación moral, pues la designación que se hace de beneficiarios en un Contrato de Seguro le concede el derecho incondicionado de disponer de la Suma Asegurada."
      >
        Beneficiario(s)
      </SectionTitle>
      <Text fontSize="10px" fontWeight="700" color={colors.bluedGray}>
        Son los que recibirán la suma asegurada en caso de fallecimiento
      </Text>
      <Text fontSize="10px" margin="0 0 25px" color={appErrors[activeStep].totalPercentage ? colors.red : colors.lightBluedGray}>
        *El porcentaje acumulado de tus beneficiarios debe ser igual a 100%.
      </Text>
      {appInfo[activeStep].beneficiaries && appInfo[activeStep].beneficiaries.map((b, index) => (
        <Beneficiary
          key={`beneficiary_${index}`}
          beneficiary={b}
          index={index}
          setAppInfo={setAppInfo}
          appErrors={appErrors}
          activeStep={activeStep}
        />
      ))}
      <AddBeneficiaryButton>
        <span onClick={addBeneficiary} className="plus" />
        <span onClick={addBeneficiary}>Añadir otro beneficiario</span>
      </AddBeneficiaryButton>
    </BeneficiariesSectionContainer>
  );


  const handleTextInputChange = (event) => {
    const { id: field, value } = event.target;
    if (field === 'weight' && value.length > 3) {
      return;
    }

    setAppInfo(() => {
      const newInfo = [...appInfo];

      newInfo[activeStep] = {
        ...newInfo[activeStep],
        [field]: value,
      }

      return newInfo;
    });
  };

  const handleTextInputChangeQuoteInfo = (event) => {
    const { id: field, value } = event.target;
    if (field !== "reference") return null;

    setQuoteInfo((prevState) => {
      return {
        ...prevState,
        [field]: value,
      }
    });
  };

  const handleCheckboxInputChange = (event) => {
    const { id: field, checked } = event.target;

    setAppInfo(() => {
      const newInfo = [...appInfo];

      newInfo[activeStep] = {
        ...newInfo[activeStep],
        [field]: checked,
      }

      return newInfo;
    });
  };

  const handleFileInputChange = (event) => {
    const { id: field, files } = event.target;
    setAppInfo(() => {
      const newInfo = [...appInfo];

      newInfo[activeStep] = {
        ...newInfo[activeStep],
        [field]: files[0],
      }

      return newInfo;
    });
  }

  const handleTogglerChange = (field, value) => {

    setAppInfo(() => {
      const newInfo = [...appInfo];

      newInfo[activeStep] = {
        ...newInfo[activeStep],
        [field]: value,
      }

      return newInfo;
    });
  };

  const handleNext = async () => {
    if (!validateStep(activeStep)) {
      return;
    }
    setIsStepValid(() => {
      const newValid = [...isStepValid];
      newValid[activeStep] = true;
      return newValid;
    })
    setActiveStep(activeStep + 1);
    switch (activeStep + 1) {
      case 0:
        history.push('/application/personal');
        break;
      case 1:
        history.push('/application/address');
        break;
      case 2:
        history.push('/application/health');
        break;
      case 3:
        const companyID = localStorage.getItem('company');
        if (companyID) {
          await getCompany(companyID)
            .then((res) => { setCompany(res.data.data) })
        }
        history.push('/application/finish');
        break;
      default:
        history.push('/application/personal');
        break;
    }
  };

  const [shouldShowTerms, setShouldShowTerms] = useState(false);

  const handleSubmit = () => {
    if (!validateStep(activeStep)) {
      return;
    }
    setShouldShowTerms(true);
  }

  const acceptedTerms = async () => {
    setShouldShowTerms(false);
    // showDocuSign();
    var form_data = new FormData();
    form_data.append("email", quoteInfo.email)
    form_data.append("appInfo", JSON.stringify(appInfo))

    if (quoteInfo.reference) form_data.append("reference", quoteInfo.reference)

    if (appInfo[3].idFront) form_data.append("idFront", appInfo[3].idFront);
    if (appInfo[3].idBack) form_data.append("idBack", appInfo[3].idBack);
    if (appInfo[3].addressProof) form_data.append("addressProof", appInfo[3].addressProof);

    try {
      await uploadApplication(form_data)
      localStorage.removeItem('company')
      history.push('/thanks');
    } catch (err) {
      console.log(err);
    }
  }

  // const showDocuSign = () => {
  //   const urlDocusign = createGNPinsurancePDF(quoteInfo, appInfo, company);
  //   openTab(urlDocusign);
  // }

  useEffect(() => {
    switch (appInfo[3].idType) {
      case 'Credencial para votar':
        setAppInfo(() => {
          const newAppInfo = [...appInfo];
          newAppInfo[3] = {
            ...newAppInfo[3],
            emisor: 'INE / IFE'
          }
          return newAppInfo;
        })
        break;
      case 'Pasaporte':
        setAppInfo(() => {
          const newAppInfo = [...appInfo];
          newAppInfo[3] = {
            ...newAppInfo[3],
            emisor: 'SRE'
          }
          return newAppInfo;
        })
        break;
      case 'Licencia de conducir':
        setAppInfo(() => {
          const newAppInfo = [...appInfo];
          newAppInfo[3] = {
            ...newAppInfo[3],
            emisor: 'Semovi'
          }
          return newAppInfo;
        })
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appInfo[3].idType]);

  const form = () => {
    switch (activeStep) {
      case 0:
        return (
          <Personal
            appInfo={appInfo}
            quoteInfo={quoteInfo}
            handleTextInputChange={handleTextInputChange}
            appErrors={appErrors}
            activeStep={activeStep}
            handleTogglerChange={handleTogglerChange}
            handleNext={handleNext}
          />
        )
      case 1:
        return (
          <Address
            appInfo={appInfo}
            activeStep={activeStep}
            appErrors={appErrors}
            handleTextInputChange={handleTextInputChange}
            handleNext={handleNext}
            zipCodeOptions={zipCodeOptions}
            handleCheckboxInputChange={handleCheckboxInputChange}
          />
        );
      case 2:
        return (
          <Salud
            appInfo={appInfo}
            activeStep={activeStep}
            appErrors={appErrors}
            handleTextInputChange={handleTextInputChange}
            handleTogglerChange={handleTogglerChange}
            handleFileInputChange={handleFileInputChange}
            handleNext={handleNext}
            setAppInfo={setAppInfo}
            emptyBeneficary={emptyBeneficary}
            setAppErrors={setAppErrors}
            referencesSection={referencesSection}
            beneficiariesSection={beneficiariesSection}
          />
        )
      case 3:
        return (
          <Finish
            appInfo={appInfo}
            quoteInfo={quoteInfo}
            handleTextInputChangeQuoteInfo={handleTextInputChangeQuoteInfo}
            activeStep={activeStep}
            appErrors={appErrors}
            handleTextInputChange={handleTextInputChange}
            handleFileInputChange={handleFileInputChange}
            handleSubmit={handleSubmit}
            needsId={needsId}
          />
        )
      default:
        return null;
    }
  };

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(true);

  return (
    <FormOuterContainer>
      <FormContainer>
        <Sidebar
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          steps={steps}
          isStepValid={isStepValid}
          bottomSection={sidebarBottomSection}
          quoteInfo={quoteInfo}
          history={history}
        />
        {form()}
        <WhatsApp />
      </FormContainer>
      <Terms
        shouldShowTerms={shouldShowTerms}
        hasAcceptedTerms={hasAcceptedTerms}
        setHasAcceptedTerms={setHasAcceptedTerms}
        acceptedTerms={acceptedTerms}
      />
    </FormOuterContainer>
  );
};

export default withRouter(Application);
