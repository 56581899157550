import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AuthenticateContainer, AuthenticateInnerContainer, AuthenticateNavbar, AuthenticateForm, AuthenticateFormDescription, AuthenticateFormInputs, GuriFormElement } from '../../common/common.styled';
import { Logo, IlusPasear } from '../../assets/images/imageRepository';
import colors from '../../constants/colors';
import { TextNexa } from '../../common/typography.styled';
import GuriInput from '../../components/form/guriInput/GuriInput';
import { GuriButton } from '../../common/buttons.styled';
import { authenticate } from '../../services/authentication';

const Login = (props) => {

  const {
    history,
  } = props;

  const emptyData = {
    email: '',
    password: '',
  };
  const [userErrors, setUserErrors] = useState({ ...emptyData });
  const [userData, setUserData] = useState({ ...emptyData });

  const validateKey = (key, value) => {
    if (!value.trim()) {
      setUserErrors(errors => ({
        ...errors,
        [key]: 'Campo requerido',
      }))
      return false;
    }
    setUserErrors(errors => ({
      ...errors,
      [key]: '',
    }))

    return true;
  };

  const handleTextInputChange = (event) => {
    const { id: field, value } = event.target;
    setUserData({
      ...userData,
      [field]: value,
    });
    validateKey(field, value);
  };

  const handleLogin = async () => {
    for (const key in userData) {
      if (!validateKey(key, userData[key])) {
        return;
      }
    }

    try {
      const response = await authenticate(userData);
      localStorage.setItem('guriToken', response.data.access_token);
      history.push('/dashboard');
    } catch (err) {
      console.log(err);
      alert('Email o contraseña inválidos')
    }
  };

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const handleForgotPassword = () => {
    setShowForgotPasswordModal(true);
    console.log('forgot password', showForgotPasswordModal);
  };

  return (
    <AuthenticateContainer>
      <AuthenticateInnerContainer>
        <AuthenticateNavbar>
          <a href="/">
            <img src={Logo} alt="logo" width="60" />
          </a>
        </AuthenticateNavbar>
        <AuthenticateForm
          minHeight="100vh"
          alignItems="initial"
          sm={{
            direction: 'column'
          }}
        >
          <AuthenticateFormInputs>
            <TextNexa
              fontSize="24px"
              lineHeight="30px"
              color={colors.blueBg}
              textAlign="left"
              maxWidth="450px"
              margin="0 auto 30px">
              Hola, ¡bienvenido!
            </TextNexa>
            <GuriFormElement fullWidth justifyContent="center">
              <GuriInput
                id="email"
                label="Correo electrónico"
                placeholder=""
                value={userData.email}
                onChange={handleTextInputChange}
                errorMessage={userErrors.email}
                maxWidth="450px"
                isInAuthenticate
              />
            </GuriFormElement>
            <GuriFormElement fullWidth justifyContent="center">
              <GuriInput
                id="password"
                label="Contraseña"
                placeholder=""
                value={userData.password}
                onChange={handleTextInputChange}
                errorMessage={userErrors.password}
                maxWidth="450px"
                isInAuthenticate
                type="password"
                helpLink={{
                  text: 'Olvidé mi contraseña',
                  onClick: handleForgotPassword,
                  alignment: 'right',
                  color: colors.blue,
                  marginTop: '6px'
                }}
              />
            </GuriFormElement>
            <GuriButton
              onClick={handleLogin}
              maxWidth="450px"
              color={colors.blue}
              textcolor={'#fffff'}
              margin="30px auto 0"
              height="50px"
              uppercase
              rectangle
              fullWidth
              sm={{
                width: '100%',
                padding: '0'
              }}
            >
              Iniciar Sesión
            </GuriButton>
            <GuriButton
              href="/register"
              maxWidth="450px"
              color="none"
              margin="0 auto"
              textColor={'black'}
              fontSize="12px"
              height="50px"
              uppercase
              rectangle
              fullWidth
            >No tengo cuenta, quiero crear una</GuriButton>
          </AuthenticateFormInputs>
          <AuthenticateFormDescription>
            <TextNexa
              fontSize="45px"
              color={colors.blueBg}
              margin="0 0 30px"
              lineHeight="50px"
              sm={{
                margin: "0 auto 30px",
                fontSize: '30px',
                lineHeight: '35px'
              }}
            >
              Invierte en tu <br />
              <span
                style={{
                  color: colors.lightYellow,
                  fontFamily: 'nexa'
                }}
              >
                bienestar
              </span>
            </TextNexa>
            <img src={IlusPasear} align="right" alt="ilus pasear" />
          </AuthenticateFormDescription>
        </AuthenticateForm>
      </AuthenticateInnerContainer>
    </AuthenticateContainer>
    // TODO: forgot password modal
  );
};

export default withRouter(Login);