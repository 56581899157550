import React from 'react';
import { QuoteSummaryContainer, SummarySection, TextWithImage, SummaryColumn, TextNexaSpan } from './Application.styled';
import { TextNexa, Text } from '../../common/typography.styled';
import { formatNumber } from '../../utils';
const SidebarBottomSection = (quoteInfo) => {
  const getLabelPromo = () => {
    if (quoteInfo && quoteInfo.cotizacion && quoteInfo.cotizacion.typeInvestment) {
      if (quoteInfo.cotizacion.typeInvestment === "retirement")
        return "¡Tu dinero estará disponible al cumplir 65 años!";
      else if (quoteInfo.cotizacion.typeInvestment === "ten_years")
        return "¡Tu dinero estará disponible en 10 años!";
      else if (quoteInfo.cotizacion.typeInvestment === "fifteen_years")
        return "¡Tu dinero estará disponible en 15 años!";
      else if (quoteInfo.cotizacion.typeInvestment === "twenty_years")
        return "¡Tu dinero estará disponible en 20 años!";
      else
        return "¡Tu dinero estará disponible al cumplir 65 años!";
    }
  }
  return (
    <QuoteSummaryContainer>
      <SummaryColumn>
        <SummarySection>
          <TextNexa textAlign="center" color="white">
            {getLabelPromo()}
          </TextNexa>
        </SummarySection>
      </SummaryColumn>
      <SummaryColumn>
        <SummarySection>
          <Text fontSize="10px" color="white" sm={{ fontSize: "8px" }}>
            {
              quoteInfo && quoteInfo.cotizacion.typeInvestment === 'retirement' ? 'Disfruta tu retiro con los que más amas y recibe:' :
                quoteInfo && quoteInfo.cotizacion.typeInvestment === 'ten_years' ? 'Recibe a los 10 años:' :
                  quoteInfo && quoteInfo.cotizacion.typeInvestment === 'fifteen_years' ? 'Recibe a los 15 años:' :
                    quoteInfo && quoteInfo.cotizacion.typeInvestment === 'twenty_years' ? 'Recibe a los 20 años:' :
                      'Recibe:'
            }
          </Text>
          <TextNexa fontSize="24px" margin="3px 0 10px" color="white" sm={{ fontSize: "14px" }}>
            {formatNumber(quoteInfo && quoteInfo.cotizacion.RTP[0].RTP)}
            {' '}
            <TextNexaSpan>
              pesos
            </TextNexaSpan>
          </TextNexa>
          <Text fontSize="10px" color="white" hiddenMobile>
            Suma asegurada en caso de fallecimiento
            {quoteInfo && quoteInfo.disabilityProtection ? ' o incapacidad total y/o permanente:' : ':'}
          </Text>
          <TextNexa fontSize="24px" margin="3px 0 10px" color="white" hiddenMobile>
            {formatNumber(quoteInfo && quoteInfo.cotizacion && quoteInfo.cotizacion.SA)} pesos
          </TextNexa>
          <Text fontSize="10px" color="white">
            Inicia tu ahorro y protección en:
          </Text>
          <TextNexa fontSize="24px" color="white" margin="3px 0 0" sm={{ fontSize: "12px" }}>
            Menos de 48 hrs
          </TextNexa>
        </SummarySection>
        <SummarySection>
          <Text fontSize="10px" color="white">
            Cumple tus sueños por sólo:
          </Text>
          <TextWithImage fontSize="24px" margin="3px 0 5px" color="white" sm={{ fontSize: "18px" }}>
            {
              quoteInfo && quoteInfo.paymentFrecuency === 'Anual'
                ? formatNumber(quoteInfo && quoteInfo.cotizacion && quoteInfo.cotizacion.NewAA)
                : formatNumber(quoteInfo && quoteInfo.cotizacion && quoteInfo.cotizacion.AM)
            }
            {' '}
            <TextNexaSpan>
              {
                quoteInfo && quoteInfo.paymentFrecuency === 'Anual'
                  ? 'al año'
                  : 'al mes'
              }
            </TextNexaSpan>
          </TextWithImage>
          <Text fontSize="8px" color="white" margin="0 0 10px" sm={{ margin: '0' }}>
            *Todas las cantidades expresadas en pesos mexicanos.
          </Text>
        </SummarySection>
      </SummaryColumn>
    </QuoteSummaryContainer>
  )
};

export default (SidebarBottomSection);