import styled from 'styled-components';
import colors from '../../constants/colors';
import mediaQueries from '../../constants/mediaQueries';

export const SidebarContainer = styled.div`
width: 340px;
flex: 0 0 340px;
display: flex;
flex-direction: column;
height: calc(100% - 20px);
padding: 0 20px 20px 0;
background: ${colors.blueGray};
position: relative;
justify-content: space-between;
${mediaQueries.sm}{
  width: 100%;
  padding: 0;
  flex: 0 0 auto;
  height: auto;
}
`;

export const StepsContainer = styled.div`
display: flex;
flex-direction: column;
padding: 0 20px;
${mediaQueries.sm}{
  flex-direction: row;
  padding: 20px;
  justify-content: center;
  align-items: center;
}
`;

export const StepContainer = styled.div`
display: flex;
padding-bottom: 10px;
position: relative;
height: 25px;
overflow: hidden;
cursor: ${props => props.disabled ? 'default' : 'pointer'};
&:before{
  content: '';
  position: relative;
  left: 11px;
  height: 30px;
  width: 3px;
  top: 50%;
  background: ${colors.blue};
}
&:last-child{
  padding-bottom: 0;
  &:before{
    display: none;
  }
}
p{
  display: flex;
  align-items: center;
}
${mediaQueries.sm}{
  flex-direction: column-reverse;
  align-items: center;
  height: auto;
  padding: 0;
  // overflow-x: visible;
  &:before{
    bottom: 8px;
    width: calc(50% + 10px);
    height: 1px;
    left: 25%;
  }
  &:after{
    content: '';
    position: absolute;
    bottom: 8px;
    width: calc(50% + 20px);
    height: 1px;
    left: -20px;
    background: ${colors.blue};
  }
  &:first-child{
    &:after{
      display: none;
    }
  }
  p{
    margin: 0 20px;
    font-size: 10px;
  }
}
`;

export const Indicator = styled.div`
background: ${colors.blue};
margin-right: 15px;
height: 20px;
width: 20px;
border-radius: 100%;
position: relative;
z-index: 2;
&:before{
  width: 10px;
  height: 10px;
  background: ${colors.white};
  content: '';
  border-radius: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  margin: auto;
  display: ${props => props.active ? 'block' : 'none'};
}

${mediaQueries.sm}{
  height: 15px;
  width: 15px;
  margin-right: 0;
  &:before{
    width: 7px;
    height: 7px;
  }
}
`;

export const BottomSectionContainer = styled.div`
// position: absolute;
bottom: 50px;
left: 40px;
right: 60px;
background: ${colors.blueGray};
z-index: 3;
flex: 1;
overflow: hidden;
align-items: flex-end;
display: flex;
${mediaQueries.sm}{
  position: static;
  display: ${props => props.mobileHidden ? 'none' : 'block'};
}
`;