import styled from 'styled-components';
import mediaQueries from '../../constants/mediaQueries';

export const WhatsAppFloat = styled.a`
  background-color: #52b23b;
  padding: 8px 20px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 15px;
  right: 10px;
  border-radius: 30px;
  text-decoration: none;
`;

export const WhatsAppImage = styled.img`
  height: 16px;
`;

export const WhatsAppSpan = styled.span`
  margin-left: 10px;
  font-family: nexa;
  font-size: 15px;
  color: white;
  padding-top: 5px;
  ${mediaQueries.sm}{
    display: none;
  }
`;
