import styled, { css } from 'styled-components';
import colors from '../constants/colors';
import mediaQueries from '../constants/mediaQueries';

export const GuriButton = styled.a`
${props => props.width && css`
width: ${props.width || 'auto'};
display: block;
`};
${props => props.fullWidth && css`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: ${props.maxWidth || 'none'};
  ${mediaQueries.sm}{
    width: ${(props.sm && props.sm.width) || 'calc(100% - 50px)'};
  }
`}
margin: ${props => (props.margin || '0')};
cursor: pointer;
text-transform: ${props => props.uppercase ? 'uppercase' : 'none'};
padding: 10px 0;
text-align: center;
font-family: nexa;
font-size: ${props => (props.fontSize || '14px')};
background: ${props => props.color || colors.lightBlue};
color: ${props => (props.textColor || 'white')};
${props => props.outlined && css`
  color: ${props.color || colors.lightBlue};
  background: transparent;
  border: 1px solid ${props.color || colors.lightBlue};
`}
border-radius: 28px;
${props => props.fontSize && css`
  border-radius: ${(`${parseInt(props.fontSize.split('px')[0]) * 2}px` || '28px')};
`}
${props => props.padding && css`
  padding: ${props.padding};
  border-radius: ${(`${parseInt(props.padding.split('px')[0]) * 2}px` || '28px')};
`}
${props => props.height && css`
  height: ${props.height};
  padding-top: 0;
  padding-bottom: 0;
  border-radius: ${props.height};
`}
${props => props.sm && css`
  ${mediaQueries.sm}{
    padding: ${props.sm.padding || '10px 25px'};
    width: ${props.sm.width || props.width || 'auto'};
    font-size: ${props.sm.fontSize || props.fontSize || '14px'};
    margin: ${props.sm.margin || props.margin || '0'}
    ${props.sm.height && css`
      height: ${props.sm.height};
      padding-top: 0;
      padding-bottom: 0;
      border-radius: ${props.sm.height};
    `}
  }
`};
${props => props.hasEffect && css`
position: relative;
overflow: hidden !important;
color: white;
background: transparent;
.background {
  position: absolute;
  height: 105%;
  width: 105%;
  top: 0;
  right: 0;
  background-color: ${props => props.color || colors.lightBlue};
  z-index: -2;
}

&::before,
&::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background: ${colors.blueBg};
  background-color: ${colors.blueBg};
  top: 50%;
  border-radius: 50%;
  z-index: -1;
}

&::before {
  transform: translate(-50%, -50%);
  left: -20px;
}

&::after {
  transform: translate(50%, -50%);
  right: -20px;
}

&:hover {
  color: white;
  transition: 1s;
}

&:hover::before {
  animation: move-left 1s both;
  animation-direction: alternate;
}
&:hover::after {
  animation: move-right 1s both;
  animation-direction: alternate;
}

`}
`;