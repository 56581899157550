import styled from 'styled-components';
import colors from '../../constants/colors';

export const Container = styled.div`
  padding: 10px 0 30px;
`;

export const BenefitCard = styled.div`
  // background-color: ${colors.white};
  justify-content: center;
  padding: 20px 0;
  color: ${colors.typography};
  border-bottom: 3px solid ${colors.blue};
  &:first-child{
    border-radius: 6px 6px 0 0;
    // border-bottom: 0;
  }
  &:not(:last-child) {
    // border-bottom: 0;
  }  
  &:last-child{
    border-radius: 0 0 6px 6px;
    border-bottom: 0;
  }
`;


export const BenefitCardInd = styled.div`
  justify-content: center;
  padding: 20px 0;
  color: ${colors.white};
`;