import styled from 'styled-components';
import colors from '../../../constants/colors';
import { TextNexa as Nexa, Text as Txt } from '../../../common/typography.styled';
import mediaQueries from '../../../constants/mediaQueries';

export const GuriSliderContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 40px 0 30px;
  overflow: visible;
  ${mediaQueries.sm}{
    margin: 0 30px;
    padding: 50px 0 30px;
  }
  .minValue{
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
    display: inline-block;
    color: ${colors.bluedGray};
    font-style: italic;
  }
  .maxValue{
    font-size: 14px;
    font-weight: 400;
    float: right;
    margin-top: 10px;
    display: inline-block;
    color: ${colors.bluedGray};
    font-style: italic;
  }
  .currency {
    font-size: 12px;
    color: ${colors.blue};
  }
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    outline: none;
    overflow: visible;
    @media screen and (max-width: 767px){
      height: 8px;
      border-radius: 4px;
    }
    -webkit-transition: .2s;
    transition: opacity .2s;
    background: linear-gradient(to right, #324DF1 0%, #324DF1 0%, #EEF0F1 0%, #EEF0F1 100%);
    overflow: visible;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 24px;
      height: 24px;
      border-radius: 100%; 
      background: ${colors.blue};
      cursor: pointer;
      @media screen and (max-width: 767px){
        width: 20px;
        height: 20px;
      }
    }
    &::-moz-range-thumb {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background: ${colors.blue};
      cursor: pointer;
      @media screen and (max-width: 767px){
        width: 20px;
        height: 20px;
      }
    }
    &::-moz-range-progress {
      background-color: ${colors.blue}; 
    }
    &::-ms-fill-lower {
      background-color: ${colors.blue}; 
    }
  }
`;

export const TextNexa = styled(Nexa)`
position: absolute;
top: 20px;
left: 20%;
transform: translateX(-50%);
overflow: visible;
`;

export const Text = styled(Txt)`
margin: 20px auto 10px;
`;