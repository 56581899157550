import React from 'react';
import { GuriForm, GuriFormContainer, GuriFormElement } from '../../../common/common.styled';
import GuriInput from '../../../components/form/guriInput/GuriInput';
import GuriSelect from '../../../components/form/guriSelect/GuriSelect';
import StepTitle from '../StepTitle';
import GuriToggler from '../../../components/form/guriToggler/GuriToggler';
import colors from '../../../constants/colors';
import { GuriButton } from '../../../common/buttons.styled';
import countries from '../../../constants/countries';

const handleCURPClick = () => {
  window.open('https://www.gob.mx/curp/', '_blank');
};

const handleRFCClick = () => {
  window.open('https://www.mi-rfc.com.mx/consulta-rfc-homoclave', '_blank');
};

const Personal = ({ appInfo, handleTextInputChange, appErrors, activeStep, handleTogglerChange, handleNext, quoteInfo }) => {
  return (
    <GuriFormContainer>
      <StepTitle
        title="Personal"
        subtitle="Datos personales"
        tooltip="El nombre completo, el RFC con homoclave, la CURP, y el domicilio fiscal son datos necesarios que requiere GNP para la emisión de constancias y facturas que servirán para la deducción de impuestos y, en su caso, para la recuperación de los mismos."
      />
      <GuriForm margin="50px 0 0">
        <GuriFormElement>
          <GuriInput
            id="name"
            label="Nombre completo"
            placeholder="Nombre"
            value={appInfo[activeStep].name}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].name}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="lastname"
            label="Apellido Paterno"
            placeholder="Apellido"
            value={appInfo[activeStep].lastname}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].lastname}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="secondLastname"
            label="Apellido Materno"
            placeholder="Apellido"
            value={appInfo[activeStep].secondLastname}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].secondLastname}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriToggler
            id="gender"
            label="Sexo"
            value={appInfo[activeStep].gender}
            options={['Hombre', 'Mujer']}
            onChange={handleTogglerChange}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriSelect
            id="maritalStatus"
            label="Estado civil"
            placeholder="Estado"
            value={appInfo[activeStep].maritalStatus}
            options={['Soltero', 'Viudo', 'Concubinato', 'Casado', 'Divorciado']}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].maritalStatus}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriSelect
            id="country"
            label="País de nacimiento"
            placeholder="País"
            value={appInfo[activeStep].country}
            options={countries}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].country}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="nationality"
            label="Nacionalidad"
            placeholder="Nacionalidad"
            value={appInfo[activeStep].nationality}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].nationality}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="birthCity"
            label="Ciudad de nacimiento"
            placeholder="Ciudad"
            value={appInfo[activeStep].birthCity}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].birthCity}
          />
        </GuriFormElement>
        <GuriFormElement alignItems="flex-start">
          <GuriInput
            id="birthState"
            label="Estado de nacimiento"
            placeholder="Estado"
            value={appInfo[activeStep].birthState}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].birthState}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="occupation"
            label="Ocupación"
            placeholder="Ocupación"
            value={appInfo[activeStep].occupation}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].occupation}
            marginBottom="20px"
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="CURP"
            label="CURP"
            placeholder="CURP"
            value={appInfo[activeStep].CURP}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].CURP}
            helpLink={{ text: '¿No conoces tu CURP?', onClick: handleCURPClick }}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="rfc"
            label="Ingresa tu RFC"
            placeholder="Ingresa tu RFC"
            value={appInfo[activeStep].rfc}
            onChange={(ev) => {ev.target.value = ev.target.value.toUpperCase(); handleTextInputChange(ev)}}
            errorMessage={appErrors[activeStep].rfc}
            helpLink={{ text: '¿No conoces tu RFC?', onClick: handleRFCClick }}
          />
        </GuriFormElement>
        <GuriFormElement fullWidth>
          <GuriInput
            id="companyName"
            label="Nombre de la empresa en la cual trabajas"
            placeholder="Nombre de la empresa / Nombre de la persona física"
            value={appInfo[activeStep].companyName}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].companyName}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="workPhone"
            label="Teléfono de trabajo / Celular"
            placeholder="Teléfono"
            value={appInfo[activeStep].workPhone}
            onChange={handleTextInputChange}
            type="tel"
            errorMessage={appErrors[activeStep].workPhone}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="monthlyIncome"
            label="Ingresos mensuales"
            placeholder="$"
            value={appInfo[activeStep].monthlyIncome}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].monthlyIncome}
            type="currency"
          />
        </GuriFormElement>
        <GuriFormElement fullWidth>
          <GuriInput
            id="workAddress"
            label="Domicilio completo del lugar de trabajo"
            hint="*Calle, Número, Colonia, Municipio o Delegación, Código Postal, País, Ciudad"
            placeholder="Domicilio"
            value={appInfo[activeStep].workAddress}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].workAddress}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="businessActivity"
            label="Actividad o giro del negocio"
            placeholder="Actividad / Giro"
            value={appInfo[activeStep].businessActivity}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].businessActivity}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="job"
            label="Puesto en el que labora"
            placeholder="Puesto"
            value={appInfo[activeStep].job}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].job}
          />
        </GuriFormElement>
        <GuriFormElement fullWidth>
          <GuriInput
            id="activities"
            label="¿En qué consisten las actividades?"
            placeholder="Actividades"
            value={appInfo[activeStep].activities}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].activities}
          />
        </GuriFormElement>
        <GuriFormElement fullWidth>
          <GuriToggler
            id="occupationRisks"
            label="Por tu ocupación, estás expuesto a uso de armas de fuego, sustancias químicas, radiaciones o riesgo por altura?"
            value={appInfo[activeStep].occupationRisks}
            options={['Si', 'No']}
            onChange={handleTogglerChange}
            width="calc(50% - 12.5px)"
          />
        </GuriFormElement>
        {appInfo[activeStep].occupationRisks === 'Si' && (
          <GuriFormElement fullWidth>
            <GuriInput
              id="occupationRisksSpecifications"
              hint="Especifique, clase, frecuencia, y desde cuándo."
              value={appInfo[activeStep].occupationRisksSpecifications}
              onChange={handleTextInputChange}
              errorMessage={appErrors[activeStep].occupationRisksSpecifications}
            />
          </GuriFormElement>
        )}
        <GuriFormElement fullWidth>
          <GuriToggler
            id="governmentPosition"
            label="¿El solicitante desempeña o ha desempeñado cargo alguno dentro del Gobierno Municipal, Estatal o Federal en los últimos 4 años?"
            value={appInfo[activeStep].governmentPosition}
            options={['Si', 'No']}
            onChange={handleTogglerChange}
            width="calc(50% - 12.5px)"
          />
        </GuriFormElement>
        {appInfo[activeStep].governmentPosition === 'Si' && (
          <GuriFormElement fullWidth>
            <GuriInput
              id="governmentPositionSpecifications"
              hint="Definir cargo y dependencia"
              value={appInfo[activeStep].governmentPositionSpecifications}
              onChange={handleTextInputChange}
              errorMessage={appErrors[activeStep].governmentPositionSpecifications}
            />
          </GuriFormElement>
        )}
        <GuriFormElement fullWidth margin="20px 0 0">
          <GuriButton
            onClick={handleNext}
            fullWidth
            color={colors.blue}
            textColor={colors.white}
          >
            Continuar
          </GuriButton>
        </GuriFormElement>
      </GuriForm>
    </GuriFormContainer>
  );
}

export default (Personal);