import styled, { css } from 'styled-components';
// import { Container } from '../../common/common.styled';
import colors from '../../constants/colors';
import mediaQueries from '../../constants/mediaQueries';

export const HeaderContaienr = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`;

export const LogOutBtn = styled.a`
  color: ${colors.blue};
`;
export const DashboardContainer = styled.div`
padding: 0 20px;
margin: 0 auto;
display: flex;
flex-direction: column;
align-items: center;
background: ${colors.blueBackground};
min-height: 100vh;
img.logo{
  align-self: flex-start;
  width: 100px;
  margin-top: 20px;
}
img.ilus{
  width: 700px;
  height: auto;
  margin: 30px 0 0;
}
`;

export const SocialLinks = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin: 50px 0;
a{
  margin: 0 10px;
  img{
    width: 20px;
    height: 20px;
    object-fit: contain;
    object-position: center;
  }
}
`;

export const GuriDashboardContainer = styled.div`
display: flex;
// flex-wrap: wrap;
justify-content: space-between;
overflow: visible;
padding: 0;

`;

export const DashboardCard = styled.div`
width: 100%;
display: flex;
flex-direction: column;
margin: ${props => (props.margin || '20px 0')};
${mediaQueries.onlyMd}{
  width: 100%;
  flex: 0 0 100%;
}
${mediaQueries.sm}{
  ${props => props.sm && css`
    ${props.sm.fullWidth && css`
      width: 100%;
      flex: 0 0 100%;
    `}
    justify-content: ${props.sm.justifyContent || props.justifyContent || 'flex-start'};
  `}
}
&:first-child {
  margin-right: 20px
}
`;

export const DashboardCardBody = styled.div`
padding: ${props => (props.padding || '20px')};
background: ${props => (props.background || colors.white)};
margin: ${props => (props.margin || '10px 0')};
`;

export const InformationCard = styled.div`
margin: 20px 0;
display: flex;
flex: 1;
flex-direction: column;
h1{
  padding: 10px;
  border: 1px solid ${colors.blueBg};
}
`;

export const RowInformation = styled.div`
display: flex;
flex: 1;
justify-content: space-between;
padding: 10px 0;
margin: 5px 0;
border-bottom: 1px solid ${colors.bluedGray};
`;

export const GroupInformation = styled.div`
display: flex;
div:not(:only-child) {
  padding-right: 10px;
  :last-child {
    padding-right: 0;
  }
}
div:not(:first-child){
  padding-left: 10px;
  border-left: 1px solid ${colors.bluedGray};
}
`;

export const ContactContainer = styled.div`
  display: flex;
  margin: 10px 0;
`;

export const ButtonContact = styled.a`
  background: ${colors.blueBtn};
  padding: 5px 10px;
  border-radius: 5px;
  color: ${colors.blue};
  text-decoration: none;
  margin-right: 10px;
  font-weight: 600;
`;

export const IllustrationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export const IllustrationGuri = styled.img`
  width: 40%;
`;

export const IllustrationIcon = styled.img`
  width: 15px;
`;

export const DownloadPolicy = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: inherit;
  margin: 1em 0;
`;

export const PDFSvg = styled.img`
  width: 30px;
  margin-right: 10px;
`;