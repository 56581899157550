import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Logo, IlusPasear, ChatImage, EmailImage, PDFImage } from '../../assets/images/imageRepository';
import { Text, Title, TextGrotesque } from '../../common/typography.styled';
import colors from '../../constants/colors';
import { DashboardContainer, GuriDashboardContainer, DashboardCard, DashboardCardBody, InformationCard, RowInformation, GroupInformation, IllustrationContainer, IllustrationGuri, ContactContainer, ButtonContact, IllustrationIcon, HeaderContaienr, LogOutBtn, DownloadPolicy, PDFSvg } from './Dashboard.styled';
import { Container } from '../../common/common.styled';
import { getUser } from '../../services/authentication';
import { formatNumber } from '../../utils';

const DashboardCardInformation = ({ title, attributes }) => {
  const showTitle = title ? (
    <Title
      fontSize="1rem"
      color={colors.blueBg}
      margin="0 0 15px"
    >
      {title}
    </Title>
  ) : (null);

  const showColumns = (title, value, index) => {
    if (typeof value === "string") {
      return (
        <RowInformation key={index}>
          <Text color={colors.gray} fontWeight="bold">{title}</Text>
          <Text color={colors.black} fontWeight="bold">{value}</Text>
        </RowInformation>
      )
    } else if (typeof value === "object") {
      return (
        <GroupInformation key={index}>
          {value.map(({ title, value }, index) => {
            return showColumns(title, value, index)
          })}
        </GroupInformation>
      )
    }
  }

  return (
    <InformationCard>
      {showTitle}
      {attributes.map(({ title, value }, index) => {
        return showColumns(title, value, index)
      })}

    </InformationCard>
  )
}

// const getInvestmentNumber = (typeInvestment = "retirement") => {
//   return typeInvestment === 'retirement' ? 'Ahorro a los 65 años:' :
//     typeInvestment === 'ten_years' ? 'Ahorro en 10 años:' :
//     typeInvestment === 'fifteen_years' ? 'Ahorro en 20 años:' :
//     typeInvestment === 'twenty_years' ? 'Ahorro en 20 años:' :
//     'Ahorro a los ${}:'
// }

const getData = (data, setStatus) => {
  if (!data) return;
  const { application, cotizacion } = data;
  let initial = {
    fullName: [
      {
        title: 'Nombre:',
        value: data.name ? data.name : ''
      },
      {
        title: 'Apellido:',
        value: data.lastname ? data.lastname : ''
      }
    ],
    contact: [
      {
        title: 'Email:',
        value: data.email ? data.email : ''
      },
      {
        title: 'Teléfono:',
        value: data && data.lada && data.phone ? `(${data.lada}) ${data.phone}` : ''
      }
    ],
    address: [
      {
        title: 'Calle:',
        value: application && application[1] && application[1].street ? application[1].street : ''
      },
      {
        title: 'Address line',
        value: [
          {
            title: 'No. Ext:',
            value: application && application[1] && application[1].exterior ? application[1].exterior : '',
          },
          {
            title: 'No. Int:',
            value: application && application[1] && application[1].interior ? application[1].interior : '',
          },
          {
            title: 'Zip Code:',
            value: application && application[1] && application[1].zip ? application[1].zip : '',
          }
        ]
      },
      {
        title: 'Address line 2',
        value: [
          {
            title: 'Ciudad:',
            value: application && application[1] && application[1].city ? application[1].city : '',
          },
          {
            title: 'Estado:',
            value: application && application[1] && application[1].state ? application[1].state : '',
          }
        ]
      }
    ],
    insurance: [
      {
        title: 'Póliza',
        value: data && data.idPolicy
      },
    ],
    documents: [
      {
        title: 'Póliza',
        value: data && data.policyPDF ? data.policyPDF : false,
      },
      {
        title: 'Condiciones generales',
        value: data && data.genCondPDF ? data.genCondPDF : false,
      },
      {
        title: 'Cotización',
        value: data && data.quotePDF ? data.quotePDF : false,
      }
    ],
    beneficiaries: [
    ],
    billing: [
      {
        title: 'Cuenta terminación',
        value: application && application[3] ? (application[3].CLABE ? application[3].CLABE.replace(/\d{12}(\d{4})/, "************$1") : application[3].cardNumber.replace(/\d{12}(\d{4})/, "************$1")) : ''
      },
      {
        title: 'Cargos:',
        value: `${formatNumber(cotizacion && (data.paymentFrecuency === 'Anual' ? cotizacion.AMAnual : cotizacion.AM))} / ${data && data.paymentFrecuency ? data.paymentFrecuency : ''}`
      },
      {
        title: 'Costo total:',
        value: formatNumber(cotizacion && cotizacion.AMAnual)
      }
    ]
  }

  if (application && application[2]) {
    application[2].beneficiaries.forEach((ele) => {
      initial['beneficiaries'].push({
        title: `${ele.percentage} ${ele.name} ${ele.lastname}`,
        value: ele.relationship
      })
    })
  }

  if (data && data.status) setStatus(data.status)
  return initial;
}

const Dashboard = ({ payloadUser }) => {
  const [data, setData] = useState(getData([]))
  const [status, setStatus] = useState('pending')

  useEffect(() => {
    getUser()
      .then((res) => (res.data.user))
      .then(async (res) => {
        let data = await getData(res, setStatus)
        setData(data)
      })
  }, [])

  const gender = payloadUser.payload.gender || "Hombre";
  return (
    <DashboardContainer>
      <Container>
        <HeaderContaienr>
          <img className="logo" src={Logo} alt="logo" />
          <LogOutBtn href="/login" onClick={() => localStorage.removeItem('guriToken')}>Cerrar sesión</LogOutBtn>
        </HeaderContaienr>
        <Title fullSize margin="60px 0 0" color={colors.blueBg}>
          Bienvenid{gender === "Hombre" ? "o" : "a"}, {payloadUser.payload.name}
        </Title>
        {showAlertStatus(status)}
        <GuriDashboardContainer>
          <DashboardCard>
            <DashboardCardBody>
              <Title
                fullSize
                fontSize="1.3rem"
                color={colors.blueBg}>Datos del asegurado
              </Title>
              <DashboardCardInformation
                title="Nombre completo"
                key="0"
                attributes={data['fullName']}
              />
              <DashboardCardInformation
                title="Información de contacto"
                key="1"
                attributes={data['contact']}
              />
              <DashboardCardInformation
                title="Dirección"
                key="2"
                attributes={data['address']}
              />
              <DashboardCardInformation
                title="Póliza"
                key="3"
                attributes={data['insurance']}
              />
            </DashboardCardBody>
          </DashboardCard>
          <DashboardCard>
            <DashboardCardBody>
              <Title fullSize fontSize="1.3rem" color={colors.blueBg}>
                Beneficiarios
              </Title>
              <DashboardCardInformation
                key="4"
                attributes={data['beneficiaries']}
              />
              <Text
                fontSize="0.8rem"
                color={colors.gray}
              >100% of the benefit has been assigned. To add new beneficiaries, update your current percentages.</Text>
            </DashboardCardBody>
            <DashboardCardBody>
              <Title fullSize fontSize="1.3rem" color={colors.blueBg}>
                Información de facturación
              </Title>
              <TextGrotesque fontWeight="600" margin="10px 0 0">
                Ajustes de facturación
              </TextGrotesque>
              <DashboardCardInformation
                key="5"
                attributes={data['billing']}
              />
            </DashboardCardBody>
            {showPolicyPDF(data['documents'])}
            <DashboardCardBody background="transparent" padding="0">
              <TextGrotesque fontWeight="600" color={colors.blueBg}>Para cualquier aclaración no dudes en contactarnos:</TextGrotesque>
              <ContactContainer>
                <ButtonContact href="https://api.whatsapp.com/send/?phone=+5215541611387">
                  <IllustrationIcon src={ChatImage} /> WhatsApp</ButtonContact>
                <ButtonContact href="mailito:hola@getguri.com">
                  <IllustrationIcon src={EmailImage} /> Mail</ButtonContact>

              </ContactContainer>

            </DashboardCardBody>
          </DashboardCard>
        </GuriDashboardContainer>
      </Container>
      <IllustrationContainer>
        <IllustrationGuri src={IlusPasear} />
      </IllustrationContainer>
    </DashboardContainer>
  );
};

const showPolicyPDF = (documents) => {
  // if (documents) return null;
  return (
    <DashboardCardBody>
      <Title fullSize fontSize="1.3rem" color={colors.blueBg}>
        Documentos de póliza
      </Title>
      {
        documents.map((el) => {
          if (el.value === false) return null
          return (
            <DownloadPolicy href={el.value} download rel="noopener noreferrer" target="_blank">
              <PDFSvg src={PDFImage} />
              {el.title}
            </DownloadPolicy>
          )
        })
      }
    </DashboardCardBody>
  )
}

const showAlertStatus = (status) => {
  if (status !== 'pending') return null;
  return (
    <GuriDashboardContainer>
      <DashboardCard margin="0 !important">
        <DashboardCardBody background={colors.yellow}>
          <p>Tu solicitud sigue siendo procesada, te enviaremos un correo electrónico para notificarte en cuanto quede validada.</p>
        </DashboardCardBody>
      </DashboardCard>
    </GuriDashboardContainer>
  );
}

Dashboard.propTypes = {
  payloadUser: PropTypes.instanceOf(Object)
};

export default Dashboard;