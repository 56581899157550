import styled, { css } from 'styled-components';
import colors from '../../../constants/colors';
import mediaQueries from '../../../constants/mediaQueries';

export const GuriFileContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
position: relative;
label{
  width: calc(${props => props.fullWidth ? '100% - 34px' : '50% - 46.5px'});
  ${mediaQueries.md}{
    width: calc(100% - 34px);
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 38px;
  font-weight: 700;
  font-size: 12px;
  color: ${colors.blue};
  text-decoration: underline;
  padding: 0 15px;
  border: 2px solid ${colors.ultraLightBlue};
  border-radius: 6px;
  cursor: pointer;
  img{
    width: 20px;
    height: 20px;
    object-fit: contain;
    object-position: center;
  }
  input{
    display: none;
  }
}
${props => props.displayerror && css`
    label {
      border-color: ${colors.red};
    }
  `}
`;

export const DownloadLink = styled.a`
position: absolute;
color: ${colors.yellow};
display: flex;
align-items: flex-end;
right: 50%;
${mediaQueries.md}{
  right: 0;
}
text-decoration: none;
font-size: 10px;
font-weight: 700;
img{
  height: 16px;
  width: auto;
  margin-left: 3px;
}
`;