import React from 'react';
import { WhatsAppFloat, WhatsAppImage } from './WhatsApp.styled';
import { WhatsAppSvg } from '../../assets/images/imageRepository';

const WhatsApp = () => {
  return(
    <WhatsAppFloat href="https://api.whatsapp.com/send/?phone=+5215541611387">
      <WhatsAppImage src={WhatsAppSvg} alt="Whats app contact" />
    </WhatsAppFloat>
  );
}

export default WhatsApp;