import styled, { css } from 'styled-components';
import colors from '../../constants/colors';
import mediaQueries from '../../constants/mediaQueries';
import { TextNexa, Text } from '../../common/typography.styled';
import { CheckedYellow } from '../../assets/images/imageRepository';

export const QuoteSummaryContainer = styled.div`
width: calc(100% - 40px);
max-width: 230px;
padding: 10px 20px;
border-radius: 26px;
background: ${colors.blueBg};
display: flex;
flex-direction: column;
margin: 0 auto;
${mediaQueries.sm}{
  max-width: 280px;
  flex-direction: row;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 20px;
}
`;

export const SummaryColumn = styled.div`
${mediaQueries.sm}{
  padding-left: 10px;
  border-left: 1px solid white;
  &:first-child{
    padding-left: 0;
    padding-right: 10px;
    flex: 0 0 80px;
    border: 0;
  }
}
`;

export const SummarySection = styled.div`
width: 100%;
padding: 10px 0;
border-bottom: 1px solid white;
&:last-child{
  border-bottom: 0;
}
${mediaQueries.sm}{
  border-bottom: 0;
  padding-bottom: 0;
  &:last-child{
    padding-bottom: 10px;
  }
}
`;

export const TextWithImage = styled(TextNexa)`
position: relative;
&:after{
  content: '';
  background: url(${CheckedYellow});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 24px;
  height: 24px;
  position: absolute;
  margin-left: 10px;
  ${mediaQueries.sm}{
    width: 10px;
    height: 10px;
    bottom: 3px;
    margin-left: 3px;
  }
}
`;

export const TextNexaSpan = styled.span`
font-family: nexa;
font-size: 24px;
${mediaQueries.sm}{
  font-size: 8px;
}
`;

export const StepTitleContainer = styled.div`
position: relative;
width: 100%;
display: flex;
${mediaQueries.sm}{
  margin-top: 0;
}
${props => props.hasTooltip && css`
  margin-top: -20px;
  .tooltip{
    flex: 1;
    margin-left: 15px;
    color: ${colors.bluedGray};
    font-weight: 700;
    font-size: 10px;
    display: none;
  }
  &:hover{
    .tooltip{
      display: inline;
    }
  }
  p:first-child{
    margin-top: 20px;
    overflow: visible;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      top: -15px;
      right: -10px;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: ${colors.darkYellow};
    }
  }
`}
`;

export const BeneficiariesSectionContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
overflow: visible;
margin-bottom: 20px;
`;

export const SectionTitle = styled(Text)`
font-weight: 700;
position: relative;
z-index: 2;
font-size: 14px;
overflow: visible;
color: ${colors.bluedGray};
margin-bottom: ${props => props.marginBottom || '0'};
${props => props.tooltipContent && css`
&:before{
  content: '';
  position: absolute;
  top: -5px;
  left: ${props => (props.tooltipLeft || '100px')};
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: ${colors.darkYellow};
}
&:after{
  content: '${props.tooltipContent}';
  position: absolute;
  top: -5px;
  left: ${props => (props.tooltipLeft ? `${parseInt(props.tooltipLeft.split('px')[0]) + 20}px` : '120px')};
  right: 0;
  font-weight: 700;
  font-size: 10px;
  color: ${colors.bluedGray};
  display: none;
  padding: 5px;
  background: ${colors.blueBg};
}
&:hover{
  &:after{
    display: block;
  }
}
`}
`;

export const BeneficiaryContainer = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
`;

export const AddBeneficiaryButton = styled.div`
margin-top: 0;
font-weight: 700;
font-size: 14px;
color: ${colors.bluedGray};
display: flex;
align-items: center;
span{
  cursor: pointer;
  padding-left: 10px;
}
span.plus{
  cursor: pointer;
  background: ${colors.ultraLightBlue};
  width: 14px;
  height: 14px;
  border-radius: 100%;
  padding: 0;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 2px;
    height: 10px;
    background: ${colors.blueBg};
    margin: auto;
  }
  &:after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    width: 10px;
    background: ${colors.blueBg};
    margin: auto;
  }
}
`;

export const ReferencesContainer = styled.div`
display: flex;
width: 100%;
flex-direction: column;
overflow: visible;
margin-top: 10px;
`;

export const ReferenceContainer = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
`;