import React, { useState, useEffect } from 'react';
import { TextNexa, Text } from '../../common/typography.styled';
import { Container, BenefitCard, BenefitCardInd } from './Benefits.styled';
import { formatNumber } from '../../utils';
import colors from '../../constants/colors';
const Benefits = (props) => {
  const [cotizacion, setCotizacion] = useState({
    RTP: [
      {
        RTP: 0,
        message: "Recibirás a los '{years}' años:",
        subtitle: "Deja que tu dinero trabaje por ti, mantenlo y sigue generando rendimientos",
      }
    ],
  })
  useEffect(() => {
    setCotizacion(props.cotizacion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cotizacion]);

  const BenefitRTP = ({ RTP, message, subtitle }) => {
    return (
      <BenefitCard>
        {message && (<TextNexa textAlign="center" color={colors.typography}>{message}</TextNexa>)}
        {subtitle && (<Text fontWeight="bold" fontSize="0.8em" margin="5px" textAlign="center" color={colors.typography}>{subtitle}</Text>)}
        <TextNexa textAlign="center" fontSize="30px" margin="15px 0 0" color={colors.typography}>
          {formatNumber(RTP && RTP)}
        </TextNexa>
      </BenefitCard>
    )
  }

  const RetirementView = () => {
    return (
      <Container>
        <BenefitCard>
          <TextNexa textAlign="center" color={colors.typography}>{cotizacion.RTP[0].message}</TextNexa>
          <TextNexa textAlign="center" fontSize="30px" margin="10px 0 0" color={colors.typography}>
            {formatNumber(cotizacion && cotizacion.RTP[0].RTP)}
          </TextNexa>
        </BenefitCard>
        <BenefitCard>
          <TextNexa textAlign="center" color={colors.typography}>Tu aportación total será únicamente de:</TextNexa>
          <TextNexa textAlign="center" fontSize="24px" margin="10px 0 0" color={colors.typography}>
            {formatNumber(cotizacion && cotizacion.ATA)}
          </TextNexa>
        </BenefitCard>
      </Container >
    )
  }

  const PartialInvestiment = () => {
    return (
      <>
        <Container>
          {cotizacion.RTP.map((el, index) => <BenefitRTP key={index} {...el} />)}
        </Container>
        <BenefitCardInd background="none">
          <TextNexa textAlign="center" color={colors.white}>Tu aportación total será únicamente de:</TextNexa>
          <TextNexa textAlign="center" fontSize="24px" margin="10px 0 0" color={colors.white}>
            {formatNumber(cotizacion && cotizacion.ATA)}
          </TextNexa>
        </BenefitCardInd>
      </>
    );
  }
  if (cotizacion.typeInvestment === "retirement") {
    return (<RetirementView />)
  } else if (cotizacion.typeInvestment === "five_years") {
    return (<PartialInvestiment />)
  } else if (cotizacion.typeInvestment === "ten_years") {
    return (<PartialInvestiment />)
  } else if (cotizacion.typeInvestment === "fifteen_years") {
    return (<PartialInvestiment />)
  } else if (cotizacion.typeInvestment === "twenty_years") {
    return (<PartialInvestiment />)
  } else {
    return (<RetirementView />)
  }



};
export default Benefits;
