export const Logo = require('./new_logo.svg');
export const ChatBlueDots = require('./chat-blue-dots.svg');
export const ChatImage = require('./chat.svg');
export const EmailImage = require('./email.svg');
export const QuoteImage = require('./quote.svg');
export const NoSpamImage = require('./no-spam.svg');
export const HereToHelpImage = require('./here-help.svg');
export const IlusHombre = require('./ilus_hombre.svg');
export const IlusPasear = require('./ilus_pasear.svg');
export const CaretDown = require('./caret-down.svg');
export const CheckedYellow = require('./checked_yellow.svg');
export const CheckedGreen = require('./checked_green.svg');
export const CardsImage = require('./cards.svg');
export const DownloadImage = require('./download.svg');
export const PDFImage = require('./pdf.svg');
export const WhatsAppSvg = require('./whatsApp.svg');