import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import ROUTES from '../../constants/routes';
import { isAuthenticated } from '../../services/authentication';

const SecuredRoute = (props) => {
  const { component: Component, path, componentProps } = props;
  const isAuth = isAuthenticated();
  return (
    <Route
      path={path}
      render={() => {
        if (!isAuth) {
          return <Redirect to={ROUTES.LOGIN} />;
        }
        return <Component {...componentProps} payloadUser={isAuth} />;
      }}
    />
  );
};

SecuredRoute.propTypes = {
  component: PropTypes.func,
  path: PropTypes.string,
  componentProps: PropTypes.instanceOf(Object),
};

SecuredRoute.defaultProps = {
  component: <Redirect to={ROUTES.LOGIN} />,
  path: ROUTES.LOGIN,
  componentProps: {},
};

export default SecuredRoute;