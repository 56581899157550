import styled from 'styled-components';
import { Container } from '../../common/common.styled';
import mediaQueries from '../../constants/mediaQueries';

export const NavbarContainer = styled(Container)`
max-width: 900px;
display: flex;
justify-content: space-between;
align-items: center;
padding: 20px 20px;
${mediaQueries.sm}{
  padding: 25px 20px;
  background: white;
}
`;

export const LogoImage = styled.img`
  height: 50px;
  width: auto;
  ${mediaQueries.sm}{
    height: 35px;
  }
`;

export const HelpContainer = styled.a`
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
text-decoration: none;
`;

export const ChatImage = styled.img`
width: 35px;
height: 35px;
${mediaQueries.sm}{
  height: 20px;
  width: 20px;
}
object-fit: contain;
object-position: center;
margin-right: 10px;
`;