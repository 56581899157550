import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Logo, IlusHombre } from '../../assets/images/imageRepository';
import { AuthenticateContainer, AuthenticateInnerContainer, AuthenticateNavbar, AuthenticateForm, AuthenticateFormDescription, AuthenticateFormInputs, GuriFormElement } from '../../common/common.styled';
import { TextNexa } from '../../common/typography.styled';
import colors from '../../constants/colors';
import GuriInput from '../../components/form/guriInput/GuriInput';
import { GuriButton } from '../../common/buttons.styled';
import { createPassword } from '../../services/authentication';

const Register = (props) => {

  const {
    history,
  } = props;

  const emptyData = {
    email: '',
    password: '',
    passwordConfirmation: '',
  };
  const [registerErrors, setRegisterErrors] = useState({ ...emptyData });
  const [registerData, setRegisterData] = useState({ ...emptyData });

  const validateKey = (key, value) => {
    if (!value.trim()) {
      setRegisterErrors(errors => ({
        ...errors,
        [key]: 'Campo requerido',
      }))
      return false;
    }
    setRegisterErrors(errors => ({
      ...errors,
      [key]: '',
    }))

    return true;
  };

  const handleTextInputChange = (event) => {
    const { id: field, value } = event.target;
    setRegisterData({
      ...registerData,
      [field]: value,
    });
    validateKey(field, value);
  };

  const hadleRegister = async () => {
    for (const key in registerData) {
      if (!validateKey(key, registerData[key])) {
        return;
      }
    }

    if (registerData.password !== registerData.passwordConfirmation) {
      setRegisterErrors(errors => ({
        ...errors,
        passwordConfirmation: 'Contraseñas no son iguales',
      }));
      return;
    }

    const { passwordConfirmation, ...newUser } = registerData;
    createPassword(newUser)
      .then((response) => {
        console.log(response)
        localStorage.setItem('guriToken', response.data.access_token);
        history.push('/dashboard');
      }, (err) => { if (err.response && err.response.data) alert(err.response.data.msg) })
      .catch((err) => console.log("Error:", err))
  }

  return (
    <AuthenticateContainer>
      <AuthenticateInnerContainer>
        <AuthenticateNavbar>
          <a href="/">
            <img src={Logo} alt="logo" width="60" />
          </a>
        </AuthenticateNavbar>
        <AuthenticateForm alignItems="initial">
          <AuthenticateFormDescription
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <TextNexa
              fontSize="32px"
              color={colors.blueBg}
              lineHeight="45px"
              textAlign="center"
              sm={{
                fontSize: '20px',
                margin: '20px',
                lineHeight: '28px'
              }}
            >
              ¡Bienvenido a la familia Güri!
            </TextNexa>
            <img src={IlusHombre} alt="ilus hombre" />
          </AuthenticateFormDescription>
          <AuthenticateFormInputs>
            <TextNexa fontSize="18px" color={colors.blueBg} margin="0 0 50px">
              Crea tu cuenta para estar informado sobre tu póliza y los beneficios que güri te ofrece
            </TextNexa>
            <GuriFormElement fullWidth justifyContent="center">
              <GuriInput
                id="email"
                label="Correo electrónico"
                placeholder=""
                value={registerData.email}
                onChange={handleTextInputChange}
                errorMessage={registerErrors.email}
                maxWidth="450px"
                isInAuthenticate
              />
            </GuriFormElement>
            <GuriFormElement fullWidth justifyContent="center">
              <GuriInput
                id="password"
                label="Crear Contraseña"
                placeholder=""
                value={registerData.password}
                onChange={handleTextInputChange}
                errorMessage={registerErrors.password}
                maxWidth="450px"
                isInAuthenticate
                type="password"
              />
            </GuriFormElement>
            <GuriFormElement fullWidth justifyContent="center">
              <GuriInput
                id="passwordConfirmation"
                label="Verificar Contraseña"
                placeholder=""
                value={registerData.passwordConfirmation}
                onChange={handleTextInputChange}
                errorMessage={registerErrors.passwordConfirmation}
                maxWidth="450px"
                isInAuthenticate
                type="password"
              />
            </GuriFormElement>
            <GuriButton
              onClick={hadleRegister}
              maxWidth="450px"
              color={colors.blue}
              textcolor={'#fffff'}
              margin="30px auto 0"
              height="50px"
              uppercase
              rectangle
              fullWidth
              sm={{
                width: '100%',
                padding: '0'
              }}
            >
              Crear cuenta
            </GuriButton>
            <GuriButton
              href="/login"
              maxWidth="450px"
              color="none"
              margin="0 auto"
              textColor={'black'}
              fontSize="12px"
              height="50px"
              uppercase
              rectangle
              fullWidth
            >Ya tengo una cuenta, iniciar sesión</GuriButton>
          </AuthenticateFormInputs>
        </AuthenticateForm>
      </AuthenticateInnerContainer>
    </AuthenticateContainer>
  );
};

export default withRouter(Register);
