import styled, { css } from 'styled-components';
import mediaQueries from '../constants/mediaQueries';

export const Title = styled.h1`
  font-size: ${props => (props.fontSize || '38px')};
  font-family: nexa;
  line-height: 1.1em;
  color: ${props => (props.color || 'black')};
  margin: ${props => (props.margin || '0')};
  font-weight: ${props => (props.fontWeight || 'normal')};
  ${props => props.sm && css`
    font-size: ${props.sm};
  `}
  ${props => props.fullSize && css`
    width: 100%;
  `}
`;

export const Text = styled.p`
font-family: muli;
font-weight: ${props => (props.fontWeight || '400')};
padding: ${props => (props.padding)};
font-size: ${props => (props.fontSize || '16px')};
text-align: ${props => (props.textAlign || 'left')};
color: ${props => (props.color || 'black')};
margin: ${props => (props.margin || '0')};
font-style: ${props => (props.fontStyle || 'normal')};
${props => props.lineHeight && css`
  line-height: ${props.lineHeight};
`}

${props => props.maxWidth && css`
  max-width: ${props.maxWidth};
`}

${props => props.width && css`
  flex: 0 0 ${props.width};
  width: ${props.width};
`}

${mediaQueries.sm}{
  ${props => props.sm && css`
    font-size: ${props.sm.fontSize || props.fontSize || '16px'};
    text-align: ${props.sm.textAlign || props.textAlign || 'left'};
    max-width: ${props.sm.maxWidth || props.maxWidth || 'none'};
    margin: ${props.sm.margin || props.margin || '0'};
    ${props.sm.lineHeight && css`
      line-height: ${props.sm.lineHeight};
    `}
  `}
  ${props => props.hiddenMobile && css`
    display: none;
  `}
}

`;

export const TextGrotesque = styled(Text)`
font-family: galano;
`;

export const TextNexa = styled(Text)`
font-family: nexa;
`;