import React from 'react';
import { GuriFormElement, GuriFormContainer, GuriForm } from '../../../common/common.styled';
import StepTitle from '../StepTitle';
import GuriInput from '../../../components/form/guriInput/GuriInput';
import GuriToggler from '../../../components/form/guriToggler/GuriToggler';
import GuriFile from '../../../components/form/guriFile/GuriFile';
import { CuestionarioPilotos } from '../../../assets/files/filesRepository';
import GuriSelect from '../../../components/form/guriSelect/GuriSelect';
import { GuriButton } from '../../../common/buttons.styled';
import colors from '../../../constants/colors';

function Salud({ 
  appInfo, 
  handleTextInputChange, 
  appErrors,
  activeStep, 
  handleTogglerChange, 
  handleFileInputChange,
  handleNext,
  beneficiariesSection,
  referencesSection,

}) {
  return (
    <GuriFormContainer>
      <StepTitle
        title="Salud"
        subtitle="Datos de salud"
      />
      <GuriForm margin="50px 0 0">
        <GuriFormElement>
          <GuriInput
            id="weight"
            label="Peso"
            placeholder="Kg"
            value={appInfo[activeStep].weight}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].weight}
            type="number"
            autocomplete="off"
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="height"
            label="Estatura"
            placeholder="M"
            value={appInfo[activeStep].height}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].height}
            type="number"
            autocomplete="off"
          />
        </GuriFormElement>
        <GuriFormElement fullWidth>
          <GuriToggler
            id="pilot"
            label="¿Has recibido instrucciones para piloto?"
            value={appInfo[activeStep].pilot}
            options={['Si', 'No']}
            onChange={handleTogglerChange}
            width="calc(50% - 12.5px)"
          />
        </GuriFormElement>
        {appInfo[activeStep].pilot === 'Si' && (
          <GuriFormElement fullWidth>
            <GuriFile
              id="pilotFile"
              hint="Cuestionario de aviación"
              file={appInfo[activeStep].pilotFile}
              onChange={handleFileInputChange}
              errorMessage={appErrors[activeStep].pilotFile}
              fileToDownload={CuestionarioPilotos}
            />
          </GuriFormElement>
        )}
        <GuriFormElement fullWidth>
          <GuriToggler
            id="criminalRecord"
            label="¿Tienes antecedentes penales?"
            value={appInfo[activeStep].criminalRecord}
            options={['Si', 'No']}
            onChange={handleTogglerChange}
            width="calc(50% - 12.5px)"
          />
        </GuriFormElement>
        {appInfo[activeStep].criminalRecord === 'Si' && (
          <>
            <GuriFormElement fullWidth>
              <GuriInput
                id="criminalRecordSpecifications"
                hint="Especifique causas, consecuencias y fecha. En caso afirmativo, enviar copia del proceso penal con la resolución del mismo."
                value={appInfo[activeStep].criminalRecordSpecifications}
                onChange={handleTextInputChange}
                errorMessage={appErrors[activeStep].criminalRecordSpecifications}
              />
            </GuriFormElement>
            <GuriFormElement fullWidth>
              <GuriFile
                id="criminalRecordFile"
                file={appInfo[activeStep].criminalRecordFile}
                onChange={handleFileInputChange}
                errorMessage={appErrors[activeStep].criminalRecordFile}
              />
            </GuriFormElement>
          </>
        )}
        <GuriFormElement fullWidth>
          <GuriToggler
            id="insuranceRejected"
            label="¿Ha sido rechazado o extraprimado en alguna solicitud de seguro?"
            value={appInfo[activeStep].insuranceRejected}
            options={['Si', 'No']}
            onChange={handleTogglerChange}
            width="calc(50% - 12.5px)"
          />
        </GuriFormElement>
        {appInfo[activeStep].insuranceRejected === 'Si' && (
          <GuriFormElement fullWidth>
            <GuriInput
              id="insuranceRejectedSpecifications"
              hint="Especifique causa y fecha"
              value={appInfo[activeStep].insuranceRejectedSpecifications}
              onChange={handleTextInputChange}
              errorMessage={appErrors[activeStep].insuranceRejectedSpecifications}
            />
          </GuriFormElement>
        )}
        <GuriFormElement fullWidth>
          <GuriToggler
            id="currentlyInsured"
            label="¿Esta actualmente asegurado en el ramo de vida?"
            value={appInfo[activeStep].currentlyInsured}
            options={['Si', 'No']}
            onChange={handleTogglerChange}
            width="calc(50% - 12.5px)"
          />
        </GuriFormElement>
        {appInfo[activeStep].currentlyInsured === 'Si' && (
          <GuriFormElement fullWidth>
            <GuriInput
              id="currentlyInsuredSpecifications"
              hint="Especifique compañía, suma asegurada y moneda (pólizas diferentes a los que obtenga gratuitamente por tarjetas de crédito o servicios)"
              value={appInfo[activeStep].currentlyInsuredSpecifications}
              onChange={handleTextInputChange}
              errorMessage={appErrors[activeStep].currentlyInsuredSpecifications}
            />
          </GuriFormElement>
        )}
        <GuriFormElement fullWidth>
          <GuriToggler
            id="familyDiabetes"
            label="¿Alguno de tus padres, hermanos, tíos o abuelos han padecido diabetes?"
            value={appInfo[activeStep].familyDiabetes}
            options={['Si', 'No']}
            onChange={handleTogglerChange}
            width="calc(50% - 12.5px)"
          />
        </GuriFormElement>
        {appInfo[activeStep].familyDiabetes === 'Si' && (
          <GuriFormElement fullWidth>
            <GuriInput
              id="familyDiabetesSpecifications"
              hint="Especifica quien"
              value={appInfo[activeStep].familyDiabetesSpecifications}
              onChange={handleTextInputChange}
              errorMessage={appErrors[activeStep].familyDiabetesSpecifications}
            />
          </GuriFormElement>
        )}
        <GuriFormElement fullWidth>
          <GuriToggler
            id="surgery"
            label="¿Has estado hospitalizado o te han hecho alguna cirugía por cualquier enfermedad, accidente, alteración congénita, reconstructiva, estética?"
            value={appInfo[activeStep].surgery}
            options={['Si', 'No']}
            onChange={handleTogglerChange}
            width="calc(50% - 12.5px)"
          />
        </GuriFormElement>
        {appInfo[activeStep].surgery === 'Si' && (
          <GuriFormElement fullWidth>
            <GuriInput
              id="surgerySpecifications"
              hint="Especifica causas, secuelas, complicaciones, fecha del evento y estado actual"
              value={appInfo[activeStep].surgerySpecifications}
              onChange={handleTextInputChange}
              errorMessage={appErrors[activeStep].surgerySpecifications}
            />
          </GuriFormElement>
        )}
        <GuriFormElement fullWidth>
          <GuriToggler
            id="criticalDisease"
            label="¿Padeces o has padecido alguna enfermedad hepática, mental, pulmonar, renal, neurológica, cardiovascular, hipertensón, diabetes, epilepsia, esclerosis múltiple, fiebre reumática, SIDA, cáncer, tumores, leucemia, lupus, alcoholismo o drogadicción?"
            value={appInfo[activeStep].criticalDisease}
            options={['Si', 'No']}
            onChange={handleTogglerChange}
            width="calc(50% - 12.5px)"
          />
        </GuriFormElement>
        {appInfo[activeStep].criticalDisease === 'Si' && (
          <GuriFormElement fullWidth>
            <GuriInput
              id="criticalDiseaseSpecifications"
              hint="Especifica cuál(es), fecha, duración y estado actual"
              value={appInfo[activeStep].criticalDiseaseSpecifications}
              onChange={handleTextInputChange}
              errorMessage={appErrors[activeStep].criticalDiseaseSpecifications}
            />
          </GuriFormElement>
        )}
        <GuriFormElement fullWidth>
          <GuriToggler
            id="unreferredCriticalDisease"
            label="¿Tiene o ha tenido alguna otra enfermedad no referida, actualmente está en tratamiento o tiene programada atención médica o quirúrgica?"
            value={appInfo[activeStep].unreferredCriticalDisease}
            options={['Si', 'No']}
            onChange={handleTogglerChange}
            width="calc(50% - 12.5px)"
          />
        </GuriFormElement>
        {appInfo[activeStep].unreferredCriticalDisease === 'Si' && (
          <GuriFormElement fullWidth>
            <GuriInput
              id="unreferredCriticalDiseaseSpecifications"
              hint="Especifique cuál (es) y/o por qué causa."
              value={appInfo[activeStep].unreferredCriticalDiseaseSpecifications}
              onChange={handleTextInputChange}
              errorMessage={appErrors[activeStep].unreferredCriticalDiseaseSpecifications}
            />
          </GuriFormElement>
        )}
        <GuriFormElement fullWidth>
          <GuriToggler
            id="alcohol"
            label="¿Ingieres bebidas alcohólicas?"
            value={appInfo[activeStep].alcohol}
            options={['Si', 'No']}
            onChange={handleTogglerChange}
            width="calc(50% - 12.5px)"
          />
        </GuriFormElement>
        {appInfo[activeStep].alcohol === 'Si' && (
          <>
            <GuriFormElement>
              <GuriInput
                id="alcoholSpecsType"
                hint="Tipo de alcohol"
                placeholder="Ron"
                value={appInfo[activeStep].alcoholSpecsType}
                onChange={handleTextInputChange}
                errorMessage={appErrors[activeStep].alcoholSpecsType}
              />
            </GuriFormElement>
            <GuriFormElement>
              <GuriInput
                id="alcoholSpecsQuantity"
                hint="Cantidad"
                placeholder="6"
                value={appInfo[activeStep].alcoholSpecsQuantity}
                onChange={handleTextInputChange}
                errorMessage={appErrors[activeStep].alcoholSpecsQuantity}
              />
            </GuriFormElement>
            <GuriFormElement>
              <GuriSelect
                id="alcoholSpecsFrecuency"
                hint="Frecuencia"
                placeholder="Frecuencia"
                value={appInfo[activeStep].alcoholSpecsFrecuency}
                options={['Semanales', 'Mensuales']}
                onChange={handleTextInputChange}
                errorMessage={appErrors[activeStep].alcoholSpecsFrecuency}
              />
            </GuriFormElement>
            <GuriFormElement>
              <GuriInput
                id="alcoholSpecsSince"
                hint="Desde cuando"
                value={appInfo[activeStep].alcoholSpecsSince}
                onChange={handleTextInputChange}
                errorMessage={appErrors[activeStep].alcoholSpecsSince}
                type="number"
              />
            </GuriFormElement>
          </>
        )}
        <GuriFormElement fullWidth>
          <GuriToggler
            id="drugs"
            label="¿Usas drogas o estupefacientes?"
            value={appInfo[activeStep].drugs}
            options={['Si', 'No']}
            onChange={handleTogglerChange}
            width="calc(50% - 12.5px)"
          />
        </GuriFormElement>
        {appInfo[activeStep].drugs === 'Si' && (
          <>
            <GuriFormElement>
              <GuriInput
                id="drugsSpecsType"
                hint="Tipo de droga"
                placeholder="Nombre"
                value={appInfo[activeStep].drugsSpecsType}
                onChange={handleTextInputChange}
                errorMessage={appErrors[activeStep].drugsSpecsType}
              />
            </GuriFormElement>
            <GuriFormElement>
              <GuriInput
                id="drugsSpecsQuantity"
                hint="Cantidad"
                placeholder="Cantidad"
                value={appInfo[activeStep].drugsSpecsQuantity}
                onChange={handleTextInputChange}
                errorMessage={appErrors[activeStep].drugsSpecsQuantity}
              />
            </GuriFormElement>
            <GuriFormElement>
              <GuriSelect
                id="drugsSpecsFrecuency"
                hint="Frecuencia"
                placeholder="Dia"
                value={appInfo[activeStep].drugsSpecsFrecuency}
                options={['Semanales', 'Mensuales']}
                onChange={handleTextInputChange}
                errorMessage={appErrors[activeStep].drugsSpecsFrecuency}
              />
            </GuriFormElement>
            <GuriFormElement>
              <GuriInput
                id="drugsSpecsSince"
                hint="Desde cuando"
                value={appInfo[activeStep].drugsSpecsSince}
                onChange={handleTextInputChange}
                errorMessage={appErrors[activeStep].drugsSpecsSince}
                type="number"
              />
            </GuriFormElement>
          </>
        )}
        <GuriFormElement fullWidth>
          <GuriInput
            id="drugsAlcoholPast"
            label="Si en el pasado hizo uso de alcohol, drogas o tabaco indique fecha de inicio, fecha de término y causa."
            value={appInfo[activeStep].drugsAlcoholPast}
            onChange={handleTextInputChange}
          />
        </GuriFormElement>
        <GuriFormElement fullWidth>
          <GuriToggler
            id="moto"
            label="¿Usas motocicleta?"
            value={appInfo[activeStep].moto}
            options={['Si', 'No']}
            onChange={handleTogglerChange}
            width="calc(50% - 12.5px)"
          />
        </GuriFormElement>
        {appInfo[activeStep].moto === 'Si' && (
          <GuriFormElement fullWidth>
            <GuriInput
              id="motoSpecifications"
              hint="Marca y cilindrada"
              value={appInfo[activeStep].motoSpecifications}
              onChange={handleTextInputChange}
              errorMessage={appErrors[activeStep].motoSpecifications}
            />
          </GuriFormElement>
        )}
        <GuriFormElement fullWidth>
          <GuriToggler
            id="privateAircrafts"
            label="¿Viajas en aeronaves particulares?"
            value={appInfo[activeStep].privateAircrafts}
            options={['Si', 'No']}
            onChange={handleTogglerChange}
            width="calc(50% - 12.5px)"
          />
        </GuriFormElement>
        {appInfo[activeStep].privateAircrafts === 'Si' && (
          <GuriFormElement fullWidth>
            <GuriInput
              id="privateAircraftsSpecifications"
              hint="Tipo de aeronave(s) y horas de vuelo al año"
              value={appInfo[activeStep].privateAircraftsSpecifications}
              onChange={handleTextInputChange}
              errorMessage={appErrors[activeStep].privateAircraftsSpecifications}
            />
          </GuriFormElement>
        )}
        <GuriFormElement fullWidth>
          <GuriToggler
            id="professionalSport"
            label="¿Practicas algún deporte de forma profesional?"
            value={appInfo[activeStep].professionalSport}
            options={['Si', 'No']}
            onChange={handleTogglerChange}
            width="calc(50% - 12.5px)"
          />
        </GuriFormElement>
        {appInfo[activeStep].professionalSport === 'Si' && (
          <GuriFormElement fullWidth>
            <GuriInput
              id="professionalSportSpecifications"
              hint="Especifica cuál"
              value={appInfo[activeStep].professionalSportSpecifications}
              onChange={handleTextInputChange}
              errorMessage={appErrors[activeStep].professionalSportSpecifications}
            />
          </GuriFormElement>
        )}
        {beneficiariesSection()}
        {/* <Text fontSize="14px" fontWeight="700" color={colors.bluedGray}>
                Domicilio completo de los beneficiarios <span style={{ fontSize: '10px' }}>(solo en caso de ser distinto al domicilio del solicitante).</span>
              </Text>
              <GuriFormElement fullWidth>
                <GuriInput
                  id="beneficiariesAddress"
                  placeholder="Dirección"
                  hint="(Calle, No. Exterior, No. Interior, Colonia, Código Postal, Municipio o Delegación, Ciudad o Población, Entidad Federativa, País)."
                  value={appInfo[activeStep].beneficiariesAddress}
                  onChange={handleTextInputChange}
                />
              </GuriFormElement> */}
        {/* <ReferencesSection
          appInfo={appInfo}
          appErrors={appErrors}
          activeStep={activeStep}
          setAppInfo={setAppInfo}
        /> */}
        {referencesSection()}
        <GuriFormElement fullWidth margin="20px 0 0">
          <GuriButton
            onClick={handleNext}
            fullWidth
            color={colors.blue}
            textColor={colors.white}
          >
            Continuar
          </GuriButton>
        </GuriFormElement>
      </GuriForm>
    </GuriFormContainer>
  );
}

export default (Salud);