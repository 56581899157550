import React from 'react';
import percentageArray from '../../constants/percentageArray';
import {
  BeneficiaryContainer,
} from './Application.styled';
import {
  GuriFormElement,
} from '../../common/common.styled';
import GuriInput from '../../components/form/guriInput/GuriInput';
import GuriSelect from '../../components/form/guriSelect/GuriSelect';
import { Text } from '../../common/typography.styled';
import colors from '../../constants/colors';

const Beneficiary = (props) => {
  const {
    index,
    beneficiary,
    setAppInfo,
    activeStep,
    appErrors,
  } = props;

  const handleTextInputChangeBeneficiary = (event) => {
    const { id: field, value } = event.target;

    setAppInfo(appInfo => {
      const newInfo = [...appInfo];
      if (field === "dueDate" ) return
      newInfo[activeStep].beneficiaries[index] = {
        ...newInfo[activeStep].beneficiaries[index],
        [field]: value,
      };

      return newInfo;
    });
  };

  const deleteBeneficiary = () => {
    setAppInfo(appInfo => {
      const newInfo = [...appInfo];

      newInfo[activeStep].beneficiaries.splice(index, 1);

      return newInfo;
    });
  }

  return (
    <BeneficiaryContainer>
      <GuriFormElement>
        <GuriInput
          id="name"
          label="Nombre completo"
          placeholder="Nombre"
          value={beneficiary.name}
          onChange={handleTextInputChangeBeneficiary}
          errorMessage={appErrors[activeStep].beneficiaries[index].name}
        />
      </GuriFormElement>
      <GuriFormElement>
        <GuriInput
          id="lastname"
          label="Apellido paterno"
          placeholder="Apellido"
          value={beneficiary.lastname}
          onChange={handleTextInputChangeBeneficiary}
          errorMessage={appErrors[activeStep].beneficiaries[index].lastname}
        />
      </GuriFormElement>
      <GuriFormElement>
        <GuriInput
          id="secondLastname"
          label="Apellido materno"
          placeholder="Apellido"
          value={beneficiary.secondLastname}
          onChange={handleTextInputChangeBeneficiary}
          errorMessage={appErrors[activeStep].beneficiaries[index].secondLastname}
        />
      </GuriFormElement>
      <GuriFormElement>
        <GuriSelect
          id="relationship"
          label="Parentesco"
          placeholder="Parentesco"
          value={beneficiary.relationship}
          options={['Padre', 'Madre', 'Hermano(a)', 'Esposo(a)', 'Concubino(a)', 'Hijo(a)']}
          onChange={handleTextInputChangeBeneficiary}
          errorMessage={appErrors[activeStep].beneficiaries[index].relationship}
        />
      </GuriFormElement>
      <GuriFormElement>
        <GuriSelect
          id="percentage"
          label="Porcentaje"
          placeholder="Porcentaje"
          value={beneficiary.percentage}
          options={percentageArray}
          onChange={handleTextInputChangeBeneficiary}
          errorMessage={appErrors[activeStep].beneficiaries[index].percentage}
        />
      </GuriFormElement>
      <GuriFormElement>
        <GuriInput
          id="dueDate"
          label="Fecha de nacimiento"
          placeholder="DD/MM/AAAA"
          value={beneficiary.dueDateNative}
          errorMessage={appErrors[activeStep].beneficiaries[index].dueDate}
          onChange={(values) => {
            setAppInfo((appInfo) => {
              const newInfo = [...appInfo];
              newInfo[activeStep].beneficiaries[index] = {
                ...newInfo[activeStep].beneficiaries[index],
                dueDateNative: values.dateWithOutFormmat,
                dueDate: values.stringFormatted
              };
              return newInfo
            });
          }}
          // quoteInfo={quoteInfo} 
          // setQuoteInfo={setQuoteInfo} 
          type="datepicker"
        />
      </GuriFormElement>
      {index > 0 && (
        <GuriFormElement
          fullWidth
          justifyContent="flex-end"
          paddingBottom="0"
        >
          <Text
            fontSize="10px"
            color={colors.lightBluedGray}
            onClick={deleteBeneficiary}
            style={{cursor: 'pointer'}}
          >
            Eliminar beneficiario
          </Text>
        </GuriFormElement>
      )}
    </BeneficiaryContainer>
  )
};

export default Beneficiary;
