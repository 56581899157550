import React from 'react';
import { withRouter, useParams } from 'react-router-dom'
import {
  DescriptionSection,
  DescriptionContainer,
  DescriptionTitle,
  DescriptionRow,
  DescriptionItem,
  DescriptionImage,
  DescriptionFooter,
  DescriptionLinksContainer,
  DescriptionLink,
  DescriptionLinkImage,
  DescriptionCircle,
} from './Description.styled';
import Navbar from '../../components/navbar/Navbar';
import { QuoteImage, NoSpamImage, HereToHelpImage, ChatImage, EmailImage } from '../../assets/images/imageRepository';
import { TextNexa, Text } from '../../common/typography.styled';
import colors from '../../constants/colors';
import { GuriButton } from '../../common/buttons.styled';

const Description = (props) => {
  const {ref} = useParams();
  if (ref) localStorage.setItem('guriReference', ref);

  const navigateToQuote = () => {
    props.history.push('/quote')
  };

  return (
    <DescriptionSection>
      <Navbar />
      <DescriptionContainer>
        <DescriptionTitle sm="20px">
          Cumple tus metas mientras <span>cuidas de ti y de tu familia</span>
        </DescriptionTitle>
        <DescriptionRow>
          <DescriptionItem>
            <DescriptionImage src={QuoteImage} />
            <TextNexa lineHeight="19px" margin="0 0 15px">Cotiza en minutos</TextNexa>
            <Text fontSize="14px" color={colors.gray} textAlign="center">
              Contrata el plan que se adapta a tu estilo de vida.
            </Text>
          </DescriptionItem>
          <DescriptionItem>
            <DescriptionImage src={NoSpamImage} />
            <TextNexa lineHeight="19px" margin="0 0 15px">No spam, lo prometemos</TextNexa>
            <Text fontSize="14px" color={colors.gray} textAlign="center">
              Sólo serás contactado por medio de güri.
            </Text>
          </DescriptionItem>
          <DescriptionItem>
            <DescriptionImage src={HereToHelpImage} />
            <TextNexa lineHeight="19px" margin="0 0 15px">Estamos aquí para ayudar</TextNexa>
            <Text fontSize="14px" color={colors.gray} textAlign="center">
              Ten tranquilidad. Soy güri, tu compañero de vida.
            </Text>
          </DescriptionItem>
        </DescriptionRow>
        <DescriptionFooter>
          <GuriButton
            fontSize="20px"
            fullWidth
            uppercase
            height="55px"
            onClick={navigateToQuote}
            sm={{ width: 'calc(100% - 50px)', fontSize: '16px' }}
            hasEffect
          >
            <div className="background"></div>
            Continuar
          </GuriButton>
          <Text fontWeight="700" color={colors.darkBlue} textAlign="center" fontSize="14px" margin="20px 0 40px">
            ¡5 minutos para conocer tu plan ideal!
          </Text>
          <DescriptionLinksContainer>
            <DescriptionLink href="https://api.whatsapp.com/send/?phone=+5215541611387">
              <DescriptionLinkImage src={ChatImage} />
              <Text fontSize="14px" color={colors.gray} textAlign="center" margin="0 5px">
                Chat en vivo
              </Text>
            </DescriptionLink>
            <DescriptionLink href="mailto:hola@getguri.com">
              <DescriptionLinkImage src={EmailImage} />
              <Text fontSize="14px" color={colors.gray} textAlign="center" margin="0 5px">
                hola@getguri.com
              </Text>
            </DescriptionLink>
          </DescriptionLinksContainer>
        </DescriptionFooter>
      </DescriptionContainer>
      <DescriptionCircle />
    </DescriptionSection>
  )
};

export default withRouter(Description);
