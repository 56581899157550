import styled from 'styled-components';
import colors from '../../constants/colors';
import mediaQueries from '../../constants/mediaQueries';

export const SliderContainer = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
width: 100%;
overflow: visible;
`;

export const FiscalContainer = styled.div`
width: 100%;
margin-bottom: 20px;
border-bottom: 1px solid #979797;
overflow: visible;
`;

export const BenefitsContainer = styled.div`
width: 100%;
margin-bottom: 20px;
border-bottom: 1px solid #979797;
overflow: visible;
`;

export const PaymentFrecuencyContainer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
overflow: visible;
margin-bottom: 10px;
${mediaQueries.sm}{
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  jusfity-content: flex-start;
}
`;

export const PaymentSelection = styled.div`
padding: 10px 0;
flex: 0 0 42%;
height: 108px;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
background: ${colors.blueLight};
border-radius: 6px;
cursor: pointer;
border: 3px solid ${props => props.active ? colors.blue : colors.blueLight};
position: relative;
overflow: visible;
.bestOption{
  position: absolute;
  display: flex;
  left: calc(100% - 15px);
  top: -10px;
  align-items: center;
  img{
    width: 30px;
    height: 30px;
  }
}

${mediaQueries.sm}{
  flex: 1 1 auto;
  width: 100%;
  margin-bottom: 20px;
}
`;

export const InvestmentContainer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
overflow: visible;
margin-bottom: 1.5em;
${mediaQueries.sm}{
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  jusfity-content: flex-start;
}
`;

export const InvestmentSelection = styled.div`
padding: 2em 10px;
flex: ${props => props.flex || '1'};
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
background: ${colors.blueLight};
border-radius: 6px;
cursor: pointer;
border: 3px solid ${props => props.active ? colors.blue : colors.blueLight};
position: relative;
overflow: visible;
margin-left: 0.7em;
margin-right: 0.7em;
.bestOption{
  position: absolute;
  display: flex;
  left: calc(100% - 15px);
  top: -10px;
  align-items: center;
  img{
    width: 30px;
    height: 30px;
  }
}

${mediaQueries.sm}{
  flex: 1 1 auto;
  width: 100%;
  margin-bottom: 20px;
}
`;