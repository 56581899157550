import React from 'react';
import { GuriModal, GuriModalContainer } from '../../common/common.styled';
import { Text } from '../../common/typography.styled';
import colors from '../../constants/colors';
import GuriCheckbox from '../../components/form/guriCheckbox/GuriCheckbox';
import { GuriButton } from '../../common/buttons.styled';

const Terms = ({shouldShowTerms, hasAcceptedTerms, setHasAcceptedTerms, acceptedTerms}) => {
  return (
    <GuriModal
      id="GuriModalTerms"
      isOpen={shouldShowTerms}
    >
      <GuriModalContainer>
        <Text fontWeight="700" fontSize="18px" color={colors.blueBg} margin="0 0 20px">
          Términos y condiciones
          </Text>
        <Text fontWeight="600" fontSize="12px" color={colors.grayedBlue} margin="0 0 40px">
          Advertencia: Toda la información de la presente Solicitud, en especial las actividades e ingresos, serán considerados información necesaria para la apreciación del riesgo y para la aceptación, en su caso, de la cobertura contratada y la suma asegurada correspondiente. Se previene al Solicitante que conforme a la Ley Sobre el Contrato de Seguro, debe declarar todos los hechos tal y como los conozca o deba conocerlos en el momento de firmar, en la inteligencia de que la no declaración o la inexacta o falsa declaración de un hecho importante que se encuentre contenido en la Solicitud para la apreciación del riesgo, podría originar la pérdida de derechos del Asegurado o del(los) Beneficiario(s) en su caso. En caso de cualquier omisión o inexacta declaración de los hechos importantes, la compañía de Seguros tiene la facultad de rescindir de pleno derecho el contrato celebrado y de abstenerse de realizar cualquier pago (Artículos 8 y 47 de la Ley Sobre el Contrato de Seguro). –Precepto(s) legal(es) disponible(s) en gnp.com.mx. En caso de que en el futuro el(los) Solicitante(s) realice(n) o se relacione(n) con actividades ilícitas, será considerado como una agravación esencial del riesgo en términos de ley.
            <br /><br />
          Aceptación: Este documento sólo constituye una Solicitud de Seguro, por tanto no representa garantía alguna de que la misma será admitida por la Institución de Seguros, ni de que en caso de aceptarse, la aceptación concuerde totalmente con los términos de la Solicitud.
          Enterado de lo que antecede y para efectos legales de esta Solicitud de Seguro declaro estar dispuesto, si fuera necesario, a someterme a un examen médico por cuenta de GNP, si ésta lo estima conveniente; lo cual no me exime de las responsabilidades en que pueda incurrir por omisiones o falsas declaraciones.
          Además autorizo a los médicos o personal que me hayan asistido o examinado, a los hospitales o clínicas a las que haya ingresado para diagnóstico o tratamiento de cualquier enfermedad y para el efecto, relevo a las personas arriba mencionadas del secreto profesional en este caso, aceptando que le proporcionen a GNP los datos mencionados.
          Asimismo, autorizo a las Compañias de Seguros a las que previamente he solicitado pólizas, para que proporcionen a GNP, la información de su conocimiento, para la correcta evaluación de esta Solicitud y que a su vez, GNP proporcione a cualquier otra empresa del ramo, la información que se derive de esta Solicitud y de otras que sean de su conocimiento, a efecto de que pueda evaluar cualquier propuesta de contratación de seguro del ramo que sea solicitada por mí y en ningún momento para fines de comercialización o mercadotecnia de productos o servicios.
          Esta información puede ser requerida al momento de presentar mi Solicitud de Seguro, en caso de fallecimiento, o en cualquier momento en que GNP lo considere oportuno.
          Hago constar que, bajo protesta de decir verdad, me he enterado debidamente y estoy de acuerdo con las condiciones que se describen en la presente Solicitud, y me he informado que tanto los datos presentados en ésta, como los requisitos médicos que GNP considere necesarios, forman parte de la misma.
          Declaro bajo protesta de decir verdad que la contratación descrita en la presente Solicitud se realiza con recursos propios, de origen lícito y únicamente en beneficio del Contratante y del (los) Solicitante(s) descrito(s) en este documento. Lo anterior, de conformidad con la resolución por la que se expiden las Disposiciones de carácter general en materia de Identificación y Conocimiento del Cliente, emitidas para tal efecto por GNP.
          El(los) Solicitante(s) y/o el contratante declara(n) que le(s) fue explicado por el Agente el contenido de las Condiciones Generales del Contrato de Seguro, principalmente en lo que se refiere a sus derechos básicos, las Coberturas, Exclusiones, Indisputabilidad, Cláusulas Generales y/o Particulares, las cuales conoce(n) y acepta(n) como parte integrante del Contrato a que se refiere esta Solicitud en términos del artículo 7 de la Ley Sobre el contrato de Seguro –Precepto legal disponible en gnp.com.mx-.
          Las exclusiones y limitaciones de este producto pueden ser consultadas en las Condiciones Generales del Contrato de Seguro. Asimismo, tiene(n) conocimiento de que las Condiciones Generales del Contrato de Seguro también se encuentran en la página gnp.com.mx o puede(n) solicitarlas nuevamente a su Agente de Seguros, llamando al 52279000 desde la Ciudad de México o al 018004009000 desde el Interior de la República o mediante e Registro de Contratos de Adhesión de Seguros de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros.
            <br /><br />
          Donación de Primas: En mi carácter de Contratante, manifiesto mi voluntad para donar las primas correspondientes del Seguro que se solicita a favor de mi familiar en línea recta (hijo, nieto, padre, abuelo) o cónyuge de conformidad con el artículo 2332 del Código Civil Federal –Precepto legal disponible en gnp.com.mx- y sus correlativos en las Entidades Federativas.
          El (los) Solicitante(s) con la firma de la Solicitud de Seguro acepta(n) la donación que el Contratante hace a su favor, en su carácter de familiar en línea recta o cónyuge.
          Datos Personales y Consentimiento: Tuve a la vista el Aviso de Privacidad Integral de GNP, el cual contiene y detalla las finalidades del tratamiento de mis datos personales, patrimoniales y sensibles. Asimismo, se me informó la disponibilidad de dicho Aviso y sus actualizaciones en la página gnp.com.mx.
          En caso de haber proporcionado datos personales de otros titulares de datos, reconozco mi obligación de informarles de esta entrega, así como los lugares en los que se encuentra disponible el Aviso de Privacidad Integral, para su consulta.
            <br /><br />
          IMPORTANTE: (Información requerida para efectos de los artículos 139 a 139 Quinquies, 193 a 199, 400 y 400 Bis del Código Penal Federal y artículos relativos. Precepto(s) legal(es) disponible(s) en gnp.com.mx)
          No he tenido nexos o vínculos con la delincuencia organizada; o he sido sujeto a procedimiento legal o investigación por los delitos establecidos en el Código Penal Federal relativos a la delincuencia organizada, en territorio nacional o extranjero, por alguna autoridad de México o por alguna autoridad extranjera cuyo gobierno tenga celebrado con México tratado internacional; o ha estado su nombre, alias o apodo, sus actividades, los bienes a asegurar o su nacionalidad, publicados en una lista oficial, nacional o extranjera, relativa a los delitos vinculados con los artículos anteriormente citados.
            <br /><br />
          Aceptación: Será una agravación esencial del riesgo, por lo que cesarán de pleno derecho las obligaciones de la compañía, si el(los) Contratante(s), Asegurado(s) o Beneficiario(s), en los términos del Art. 492 de la Ley de Instituciones de Seguros y Fianzas y sus disposiciones generales, fuere(n) condenado(s) mediante sentencia definitiva que haya causado estado, por cualquier delito vinculado o derivado de lo establecido en los Artículos 139 a 139 Quinquies, 193 a 199, 400 y 400 Bis del Código Penal Federal y/o cualquier artículo relativo a la delincuencia organizada en territorio nacional; dicha sentencia podrá ser emitida por cualquier autoridad competente del fuero local o federal, o legalmente reconocida por el Gobierno Mexicano; o si el nombre del(los) Contratante(s), Asegurado(s) o Beneficiario(s), sus actividades, bienes cubiertos por la póliza o sus nacionalidades es(son) publicado(s) en alguna lista emitida en términos de la fracción X disposición Vigésima Novena, fracción V disposición Trigésima Cuarta o disposición Quincuagésima Sexta de la RESOLUCIÓN por la que se expiden las Disposiciones de carácter general a que se refiere el artículo 140 de la Ley General de Instituciones y Sociedades Mutualistas de Seguros -Precepto(s) legal(s) disponible(s) en gnp.com.mx-.
          En su caso, las obligaciones del contrato serán restauradas una vez que la aseguradora tenga conocimiento de que el nombre del (de los) Contratante(s), Asegurado(s) o Beneficiario(s) deje de encontrarse en las listas antes mencionadas. La compañía consignará ante la autoridad jurisdiccional competente, cualquier cantidad que derivada de este Contrato de Seguro pudiera quedar a favor de la persona o personas a las que se refiere el párrafo anterior, con la finalidad de que dicha autoridad determine el destino de los recursos. Toda cantidad pagada no devengada que sea pagada con posterioridad a la realización de las condiciones previamente señaladas, será consignada a favor de la autoridad correspondiente.
            <br /><br />
          Omisión de pago de prima: Solicito y autorizo a la institución financiera o bancaria que corresponda para que realice, a mi nombre el pago por los conceptos, periodicidad y montos que se detallan, con cargo a mi tarjeta de débito o crédito, o a mi cuenta bancaria identificada por la CLABE que se cita, a favor de Grupo Nacional Provincial, S.A.B. (GNP), liberando al banco de cualquier responsabilidad. Todos los cargos serán realizados al inicio de la vigencia de cada periodo, en caso de ser día inhábil se cobrara el día hábil siguiente. El Solicitante y/o Contratante no quedará liberado del cumplimiento de su obligación de pago de la prima en términos de lo previsto en el Artículo 40 de la Ley Sobre el Contrato de Seguro (Precepto legal disponible en gnp.com.mx), quedando GNP liberado de cualquier responsabilidad por la cancelación correspondiente. Cuando la transacción sea con cargo a tarjeta de débito o crédito, cuenta bancaria, o mediante transferencia o depósito bancario, el estado de cuenta, recibo, comprobante, folio o número de confirmación de la transacción que corresponda hará prueba plena del pago hasta en tanto GNP entregue el comprobante de pago correspondiente.
            <br /><br />
          CONSENTIMIENTO PARA LA ENTREGA DE LA DOCUMENTACIÓN CONTRACTUAL: Otorgo mi consentimiento para que la documentación contractual y cualquier otra información relacionada con este seguro, me sea entregada a través de la vía digital.
            <br /><br />
          Por lo anterior, autorizo a Grupo Nacional Provincial S.A.B. (GNP), a realizar la entrega de la documentación contractual y cualquier otra información relacionada con este seguro en formato PDF (portable document format), o cualquier otro formato electrónico equivalente, a la cuenta de correo electrónico: *Que se ponga aquí el mail en automático de lo que lleno en la solicitud
            <br /><br />
          En cumplimiento a lo dispuesto en el artículo 202 de la Ley de Instituciones de Seguros y de Fianzas, la documentación contractual y la nota técnica que integran este producto de seguro, quedaron registradas ante la Comisión Nacional de Seguros y Fianzas, a partir del día 6 de Marzo de 2020, con el número CNSF-S0043-0052-2019/CONDUSEF-003160-04.
            <br /><br />
          En caso de requerir información contáctenos al: (55)5227 9000 a nivel nacional o visite gnp.com.mx
            <br /><br />
          Solicito y autorizo a la Institución Financiera o Bancaria que corresponda, para que realice a mi nombre el pago por los conceptos, periodicidad y montos que se detallan, con cargo a mi tarjeta de Débito o Crédito, o a mi cuenta Bancaria identificada por la CLABE que se cita, a favor de Grupo Nacional Provincial, S.A.B. (GNP), liberando al banco de cualquier responsabilidad.
            <br /><br />
          Los montos de esta cotización son aproximados y estan sujetos a modificaciones del cotizador de GNP
            <br /><br />
          Unidad Especializada de Atención a Usuarios (UNE): Para cualquier aclaración o duda no resuelta relacionada con su seguro, le sugerimos ponerse en contacto con la Unidad Especializada de Atención a Usuarios (UNE) de Grupo Nacional Provincial, S.A.B. ubicada en Av. Cerro de las Torres # 395, Colonia Campestre Churubusco, Delegación Coyoacán, C.P. 04200, comunicarse al teléfono (55) 5227 9000 a nivel nacional o al correo electrónico: <a href="mailto:unidad.especializada@gnp.com.mx">unidad.especializada@gnp.com.mx</a> Bajo protesta de decir verdad, declaro que el contenido de la presente Solicitud es cierta y, bajo el principio de la buena fe, he (hemos) llenado el de mi (nuestro) puño y letra.
          </Text>
        <GuriCheckbox
          id="hasAcceptedTerms"
          value={hasAcceptedTerms}
          onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
          label="Acepto los datos de términos de privacidad"
          labelColor={colors.grayedBlue}
          labelSize="16px"
          maxWidth="370px"
          margin="0 auto 20px"
        />
        {hasAcceptedTerms && (
          <GuriButton
            id="GuriModalTermsBtn"
            margin="20px auto 0"
            width="220px"
            color={colors.blue}
            textColor={colors.white}
            onClick={acceptedTerms}
          >
            Continuar
            </GuriButton>
        )}

      </GuriModalContainer>
    </GuriModal>
  )
}

export default(Terms);