import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../../constants/colors';
import { Text } from '../../../common/typography.styled';
import { GuriFileContainer, DownloadLink } from './GuriFile.styled';
import { CheckedGreen, DownloadImage } from '../../../assets/images/imageRepository';
import { ErrorText } from '../guriInput/GuriInput.styled';

const GuriFile = (props) => {
  const {
    id,
    hint,
    label,
    onChange,
    file,
    errorMessage,
    fullWidth,
    fileToDownload,
  } = props;

  return (
    <GuriFileContainer
      displayerror={errorMessage ? 1 : 0}
      fullWidth={fullWidth}
    >
      {fileToDownload && (
        <DownloadLink href={fileToDownload} download>
          Descargar <img src={DownloadImage} alt="download" />
        </DownloadLink>
      )}
      {label && (
        <Text fontSize="14px" fontWeight="700" color={colors.typography} margin="0 0 10px">
          {label}
        </Text>
      )}
      {hint && (
        <Text fontSize="10px" fontWeight="700" color={colors.bluedGray} margin="0 0 10px">
          {hint}
        </Text>
      )}
      <label>
        Cargar archivo
        {file && (
          <img src={CheckedGreen} alt="green check" />
        )}
        <input id={id} type="file" onChange={onChange} />
      </label>
      <ErrorText fontSize="10px" color={colors.red}>
        {errorMessage}
      </ErrorText>
    </GuriFileContainer>
  );
};

GuriFile.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  file: PropTypes.instanceOf(Object),
  errorMessage: PropTypes.string,
  fullWidth: PropTypes.bool,
  fileToDownload: PropTypes.string,
};

GuriFile.defaultProps = {
  label: null,
  hint: null,
  file: null,
  errorMessage: '',
  fullWidth: false,
  fileToDownload: null,
};


export default GuriFile;