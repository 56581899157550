import axios from 'axios';
import jwt from 'jsonwebtoken';

export const registerUser = (user) => {
  return axios.post(`${process.env.REACT_APP_BACK_URL}/users/register`, user);
};
export const createPassword = (user) => {
  return axios.post(`${process.env.REACT_APP_BACK_URL}/users/password/create`, user);
};

export const authenticate = (user) => {
  return axios.post(`${process.env.REACT_APP_BACK_URL}/users/authenticate`, user);
};

export const logout = () => {
  localStorage.removeItem('guriToken');
};

export const isAuthenticated = () => {
  let token = localStorage.getItem('guriToken');
  if (!token) return false;
  token = token.replace('JWT ', '');
  var decodedToken = jwt.decode(token, { complete: true });
  var dateNow = new Date();

  if(decodedToken.exp < dateNow.getTime()) return false;
  return decodedToken;
};

export const getUser = () => {
  let token = localStorage.getItem('guriToken');
  if (!token) return false;
  // token = token.replace('JWT ', '');
  return axios.get(`${process.env.REACT_APP_BACK_URL}/users/authenticate`, {
    headers: {
      'Authorization': token,
    }
  });
};

export const validateUser = (user) => {
  return axios.post(`${process.env.REACT_APP_BACK_URL}/users/validation`, user);
}
