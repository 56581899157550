const ROUTES = {
  REFERNCES: '/ref/:ref',
  DESCRIPTION: '/',
  QUOTE: '/quote',
  QUOTE_ROUTES: '/quote/:view',
  APPLICATION: '/application',
  APPLICATION_ROUTES: '/application/:view',
  REGISTER: '/register',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  THANKS: '/thanks'
};

export default ROUTES;