import React from 'react';
import StepTitle from '../StepTitle';
import { GuriForm, GuriFormElement, GuriFormContainer } from '../../../common/common.styled';
import GuriInput from '../../../components/form/guriInput/GuriInput';
import countries from '../../../constants/countries';
import GuriSelect from '../../../components/form/guriSelect/GuriSelect';
import GuriCheckbox from '../../../components/form/guriCheckbox/GuriCheckbox';
import colors from '../../../constants/colors';
import { GuriButton } from '../../../common/buttons.styled';

const Address = ({ 
  appInfo,
  activeStep, 
  appErrors, 
  handleTextInputChange, 
  handleCheckboxInputChange, 
  handleNext,
  zipCodeOptions
}) => {
  return (
    <GuriFormContainer>
      <StepTitle
        title="Domicilio Personal"
        subtitle="Datos de domicilio"
        tooltip="En caso de no tener residencia en México, indique su domicilio en el extranjero"
      />
      <GuriForm margin="50px 0 0">
        <GuriFormElement>
          <GuriInput
            id="street"
            label="Calle"
            placeholder="Calle"
            value={appInfo[activeStep].street}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].street}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="exterior"
            label="Número exterior"
            placeholder="Número"
            value={appInfo[activeStep].exterior}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].exterior}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="interior"
            label="Número interior"
            placeholder="Número"
            value={appInfo[activeStep].interior}
            onChange={handleTextInputChange}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriSelect
            id="country"
            label="País"
            placeholder="País"
            options={countries}
            value={appInfo[activeStep].country}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].country}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="delegacion"
            label="Municipio o Delegación"
            placeholder="Municipio o Delegación"
            value={appInfo[activeStep].delegacion}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].delegacion}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="zip"
            label="Código Postal"
            placeholder="C.P."
            options={zipCodeOptions}
            value={appInfo[activeStep].zip}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].zip}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="state"
            label="Entidad federativa"
            placeholder="Entidad"
            value={appInfo[activeStep].state}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].state}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriInput
            id="city"
            label="Ciudad"
            placeholder="Ciudad"
            value={appInfo[activeStep].city}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].city}
          />
        </GuriFormElement>
        <GuriFormElement>
          <GuriSelect
            id="colonia"
            label="Colonia"
            placeholder="Colonia"
            options={zipCodeOptions}
            value={appInfo[activeStep].colonia}
            onChange={handleTextInputChange}
            errorMessage={appErrors[activeStep].colonia}
          />
        </GuriFormElement>
        <GuriFormElement fullWidth paddingBottom="0" margin="20px 0 0">
          <GuriCheckbox
            id="receiveInfo"
            value={appInfo[activeStep].receiveInfo}
            onChange={handleCheckboxInputChange}
            label="Quiero recibir información sobre los productos y soluciones de güri."
          />
        </GuriFormElement>
        <GuriFormElement fullWidth margin="20px 0 0">
          <GuriButton
            onClick={handleNext}
            fullWidth
            color={colors.blue}
            textColor={colors.white}
          >
            Continuar
                </GuriButton>
        </GuriFormElement>
      </GuriForm>
    </GuriFormContainer>
  );
}

export default (Address);