import React from 'react';
import PropTypes from 'prop-types';
import { GuriInputContainer, ErrorText, HelpLink, InputsContainer } from './GuriInput.styled';
import colors from '../../../constants/colors';
import { Text } from '../../../common/typography.styled';

const GuriDoubleInput = (props) => {
  const {
    label,
    hint,
    onChange,
    maxWidth,
    alignment,
    sm,
    helpLink,
    marginBottom,
    errorMessage,
    first,
    second
  } = props;
  return (
    <GuriInputContainer
      maxWidth={maxWidth}
      alignment={alignment}
      sm={sm}
      displayerror={errorMessage ? 1 : 0}
      hasHelpLink={helpLink ? 1 : 0}
      marginBottom={marginBottom}
    >
      {label && (
        <label>
          {label}
        </label>
      )}
      {hint && (
        <Text fontSize="10px" fontWeight="700" color={colors.bluedGray} margin={`${label ? '-10px' : '0'} 0 10px`}>
          {hint}
        </Text>
      )}
      <InputsContainer
        firstWidth={first.width}
        secondWidth={second.width}
      >
        <input id={first.id} type={first.type} value={first.value} onChange={onChange} maxLength={first.maxlength} placeholder={first.placeholder} />
        <input id={second.id} type={second.type} value={second.value} onChange={onChange} maxLength={second.maxlength} placeholder={second.placeholder} />
      </InputsContainer>
      {helpLink && (
        <HelpLink href={helpLink.url} target="_blank">
          {helpLink.text}
        </HelpLink>
      )}
      <ErrorText
        fontSize="10px"
        color={colors.red}
        hasHelpLink={helpLink ? 1 : 0}
      >
        {errorMessage}
      </ErrorText>
    </GuriInputContainer>
  )
};

GuriDoubleInput.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  alignment: PropTypes.string,
  sm: PropTypes.instanceOf(Object),
  helpLink: PropTypes.instanceOf(Object),
  marginBottom: PropTypes.string,
  first: PropTypes.instanceOf(Object).isRequired,
  second: PropTypes.instanceOf(Object).isRequired,
  errorMessage: PropTypes.string,
};

GuriDoubleInput.defaultProps = {
  label: null,
  hint: null,
  maxWidth: 'none',
  alignment: 'left',
  sm: null,
  helpLink: null,
  marginBottom: null,
  errorMessage: '',
};

export default GuriDoubleInput;