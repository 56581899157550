import React from 'react';
import { StepTitleContainer } from './Application.styled';
import { TextNexa, Text } from '../../common/typography.styled';
import colors from '../../constants/colors';

const StepTitle = ({title, subtitle, tooltip}) => {
  return (
    <StepTitleContainer
      hasTooltip={tooltip ? 1 : 0}
    >
      <div style={{ overflow: 'visible' }}>
        <TextNexa fontSize="30px" color={colors.blue}>
          {title}
        </TextNexa>
        <Text fontSize="14px" fontWeight="700" color={colors.typography}>
          {subtitle}
        </Text>
      </div>
      {tooltip && (
        <span className="tooltip">
          {tooltip}
        </span>
      )}
    </StepTitleContainer>
  )
};

export default(StepTitle)