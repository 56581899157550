import React from 'react';
import PropTypes from 'prop-types';
import { GuriSwitchContainer, GuriSwitchIndicator, GuriSwitchInformation } from './GuriSwitch.styled';
import colors from '../../../constants/colors';
import { Text } from '../../../common/typography.styled';

const GuriSwitch = (props) => {
  const {
    value,
    name,
    description,
    setQuoteInfo,
    id,
  } = props;

  return (
    <GuriSwitchContainer>
      <GuriSwitchIndicator
        active={value}
        onClick={() => setQuoteInfo(quote => ({
          ...quote,
          [id]: !value,
        }))}
      />
      <GuriSwitchInformation>
        <Text fontSize="10px" fontWeight="600" fontStyle="italic" color={colors.typography}>
          <span style={{fontWight: '700', color: colors.typography, fontStyle: 'normal', textTransform: 'uppercase'}}>
            {name}
          </span>
          {' '}<div dangerouslySetInnerHTML={{ __html: description }} />
        </Text>
      </GuriSwitchInformation>
    </GuriSwitchContainer>
  );
};

GuriSwitch.propTypes = {
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  setQuoteInfo: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default GuriSwitch;