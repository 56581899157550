import React from 'react';
import PropTypes from 'prop-types';
import { GuriInputContainer, ErrorText } from '../guriInput/GuriInput.styled';
import colors from '../../../constants/colors';
import { Text } from '../../../common/typography.styled';

const GuriSelect = (props) => {
  const {
    id,
    label,
    onChange,
    value,
    hint,
    options,
    placeholder,
    errorMessage,
  } = props;
  return (
    <GuriInputContainer
      displayerror={errorMessage ? 1 : 0}
    >
      {label && (
        <label>
          {label}
        </label>
      )}
      {hint && (
        <Text fontSize="10px" fontWeight="700" color={colors.bluedGray} margin={`${label ? '-20px' : '0'} 0 15px`}>
          {hint}
        </Text>
      )}
      <select
        id={id}
        value={value}
        onChange={onChange}
        style={{color: value ? colors.darkBlue : colors.bluedGray}}
      >
        <option value="" disabled>{placeholder}</option>
        {options.map((o) => (
          <option key={o} value={o}>{o}</option>
        ))}
      </select>
      <ErrorText fontSize="10px" color={colors.red}>
        {errorMessage}
      </ErrorText>
    </GuriInputContainer>
  )
};

GuriSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  placeholder: PropTypes.string,
  hint: PropTypes.string,
  errorMessage: PropTypes.string,
  options: PropTypes.instanceOf(Array).isRequired,
};

GuriSelect.defaultProps = {
  label: null,
  hint: null,
  placeholder: '',
  errorMessage: '',
};

export default GuriSelect;