import React from 'react';
import { Logo, IlusHombre } from '../../assets/images/imageRepository';
import { TextNexa, Text } from '../../common/typography.styled';
import colors from '../../constants/colors';
import { DashboardContainer, SocialLinks } from './Thanks.styled';

const Dashboard = (props) => {
  return (
    <DashboardContainer>
      <img className="logo" src={Logo} alt="logo" />
      <TextNexa fontSize="30px" color={colors.blueBg}>
        ¡Bienvenido a güri!
      </TextNexa>
      <Text color={colors.blueBg} textAlign="center">
        Tu solicitud está en revisión, muy pronto nos<br />
        pondremos en contacto
      </Text>
      <img className="ilus" src={IlusHombre} alt="ilus" />
      <SocialLinks>
        <a href="https://www.facebook.com/G%C3%BCri-110732957200710/?view_public_for=110732957200710" target="_blank" rel="noopener noreferrer">
          <img src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-gray-facebook-48.png" alt="Facebook" />
        </a>
        <a href="http://www.twitter.com/getguri" target="_blank" rel="noopener noreferrer">
          <img src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-gray-twitter-48.png" alt="Twitter" />
        </a>
        <a href="https://www.instagram.com/getguri" target="_blank" rel="noopener noreferrer">
          <img src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-gray-instagram-48.png" alt="Instagram" />
        </a>
        <a href="https://www.linkedin.com/company/g%C3%BCri/" target="_blank" rel="noopener noreferrer">
          <img src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-gray-linkedin-48.png" alt="Linkedin" />
        </a>
        <a href="https://medium.com/@getguri" target="_blank" rel="noopener noreferrer">
          <img src="https://cdn-images.mailchimp.com/icons/social-block-v2/outline-gray-medium-48.png" alt="Medium" />
        </a>
      </SocialLinks>
    </DashboardContainer>
  );
};

export default Dashboard;