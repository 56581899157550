import styled, { css } from 'styled-components';
import colors from '../../../constants/colors';
import mediaQueries from '../../../constants/mediaQueries';


export const GuriTogglerContainer = styled.div`
display: flex;
padding: 4px;
border-radius: 5px;
border: 1px solid transparent;
background: ${colors.ultraLightBlue};
width: calc(${props => (props.width || '100%')} - 11px);
margin: ${props => (props.alignment === 'center' ? '0 auto' : '0')};
overflow: visible;
${mediaQueries.sm}{
  ${props => props.sm && css`
    width: calc(${props.sm.width || props.width || '100%'} - 14px);
    margin: ${props.sm.margin || props.margin || '0'};
  `}
}
`;

export const GuriTogglerOption = styled.div`
flex: 1;
flex-shrink: 0;
border-radius: 6px;
display: flex;
align-items: center;
justify-content: center;
padding: 6px 2px;
background-color: ${props => props.active ? '#AAC3D1' : 'transparent'};
cursor: pointer;
overflow: visible;
p{
  ${mediaQueries.sm}{
    font-size: 11px;
  }
}
${props => props.hasTooltip && css`
position: relative;
&:before{
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: ${colors.darkYellow};
}
span.tooltip{
  position: absolute;
  top: -5px;
  left: 100%;
  font-weight: 700;
  font-size: 10px;
  color: ${colors.bluedGray};
  display: none;
  padding: 5px;
  background: ${colors.blueBg};
  width: 200px;
  ul{
    padding-left: 20px;
    li {
      overflow: visible;
      list-style-type: disc;
    }
  }
  ${mediaQueries.md}{
    top: 100%;
    left: auto;
    right: 0;
  }
}
&:hover{
  span.tooltip{
    display: block;
  }
}
`}
`;