import React from 'react';
import PropTypes from 'prop-types';
import Navbar from '../navbar/Navbar';
import { SidebarContainer, StepsContainer, StepContainer, Indicator, BottomSectionContainer } from './Sidebar.styled';
import { TextNexa } from '../../common/typography.styled';
import colors from '../../constants/colors';

const Sidebar = (props) => {
  const {activeStep, setActiveStep, steps, isStepValid, isQuote, bottomSection, quoteInfo, history, } = props;
  const Steps = () => {

    const Step = ({step, index}) => {
      const active = activeStep === index;

      const handleClick = index => {
        if (!index) {
          setActiveStep(0);
          history.push(step.link)
        } else {
          if (isStepValid[index - 1]) {
            setActiveStep(index);
            history.push(step.link)
          }
        }
      };

      return (
        <StepContainer
        onClick={() => handleClick(index)}
        disabled={!(!index || isStepValid[index - 1])}
        >
          <Indicator active={active} />
          <TextNexa color={active ? colors.grayedBlue : colors.bluedGray}>
            {step.name}
          </TextNexa>
        </StepContainer>
        );
    };

    return (
      <StepsContainer>
        {
      steps.map((step, index) => (
        <Step step={step} index={index} key={index} />
      ))
      }
      </StepsContainer>
    )
  };

  let bottomSectionComponent;
  if (activeStep === 3) {
    bottomSectionComponent = (
      <BottomSectionContainer mobileHidden={isQuote && !activeStep}>
        {bottomSection(quoteInfo)}
      </BottomSectionContainer>
    )
  }

  return (
    <SidebarContainer>
      <div>
        <Navbar />
        <Steps />
      </div>
      {bottomSectionComponent}
    </SidebarContainer>
    );
};

Sidebar.propTypes = {
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  steps: PropTypes.instanceOf(Array).isRequired,
  isStepValid: PropTypes.instanceOf(Array).isRequired,
  isQuote: PropTypes.bool,
  bottomSection: PropTypes.func,
  quoteInfo: PropTypes.instanceOf(Object),
}

Sidebar.defaultProps = {
  isQuote: false,
  bottomSection: () => {
  },
  quoteInfo: () => {
  },
};

export default Sidebar;